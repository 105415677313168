import "core-js/modules/es.array.push.js";
import AdminService from "@/services/admin.service";
export const state = {
  notifications: []
};
export const mutations = {
  EDIT_NOTIFICATION(state, payload) {
    state.notifications[payload.index] = payload;
    state.notifications = [...state.notifications];
  },
  SET_NOTIFICATION(state, payload) {
    state.notifications = payload;
  },
  SET_ADD_NOTIFICATION(state, payload) {
    state.notifications.push(payload);
  },
  SET_DELETE_NOTIFICATION(state, key) {
    state.notifications.splice(key, 1);
  },
  SET_UNPUBLISH_NOTIFICATION(state, payload) {
    state.notifications = payload;
  }
};
export const actions = {
  getNotifications({
    commit
  }) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/notifications/get`).then(({
        data: {
          data
        }
      }) => {
        if (data) {
          commit('SET_NOTIFICATION', data);
        }
        resolve(data);
      }).catch(err => reject(err));
    });
  },
  publishNotification({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/notification/show`, payload).then(({
        data: {
          message
        }
      }) => {
        resolve(message);
      }).catch(err => reject(err));
    });
  },
  unpublishNotification({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/notification/hide`, payload).then(message => {
        // commit('SET_UNPUBLISH_NOTIFICATION', payload.key)
        resolve(message);
      }).catch(err => reject(err));
    });
  },
  saveNotification({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/notification/create`, payload).then(({
        data: {
          data,
          message
        }
      }) => {
        commit('SET_ADD_NOTIFICATION', data);
        resolve(message);
      }).catch(err => reject(err));
    });
  },
  editNotification({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/notification/edit`, payload).then(({
        data: {
          message
        }
      }) => {
        commit('EDIT_NOTIFICATION', payload.stateData);
        resolve(message);
      }).catch(err => reject(err));
    });
  },
  deleteNotification({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/notification/delete`, payload).then(({
        data: {
          message
        }
      }) => {
        commit('SET_DELETE_NOTIFICATION', payload.key);
        resolve(message);
      }).catch(err => reject(err));
    });
  } //
};
export const getters = {
  notifications: state => state.notifications
};