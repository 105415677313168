import apiService from "../../services/api.service";
import Storage from "../../services/storage";

const storage = new Storage();

export const state = {
  messages: [],
  templates: [],
};
export const mutations = {
  ADD_MESSAGES(state, payload) {
    state.messages.push(payload);
  },
  ADD_TEMPLATES(state, payload) {
    state.templates = payload;
  },
  ADD_TEMPLATE(state, payload) {
    state.templates.unshift(payload);
  },
  DELETE_TEMPLATE(state, key) {
    state.templates.splice(key, 1);
  },
  EDIT_TEMPLATE(state, payload) {
    state.templates[payload.key].body = payload.data.body;
    state.templates[payload.key].title = payload.data.title;
    state.templates = [...state.templates];
  },
};
export const actions = {
  sendQuickSMS({ commit }, payload) {
    payload.country_code = storage.getSavedState("currentUser").country || "GH";
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/sms/quick`, payload)
        .then(({ data }) => {
          if (data) {
            commit("ADD_MESSAGES", data.data);
          }
          resolve(data.data);
        })
        .catch((err) => reject(err));
    });
  },
  sendGroupSMS({ commit }, payload) {
    payload.country_code = storage.getSavedState("currentUser").country || "GH";
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/sms/group`, payload)
        .then(({ data }) => {
          if (data) {
            commit("ADD_MESSAGES", data.data);
          }
          resolve(data.data);
        })
        .catch((err) => reject(err));
    });
  },
  sendExcelSMS({ commit }, payload) {
    payload.country_code = storage.getSavedState("currentUser").country || "GH";
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/sms/excel`, payload)
        .then(({ data }) => {
          if (data) {
            commit("ADD_MESSAGES", data.data);
          }
          resolve(data.data);
        })
        .catch((err) => reject(err));
    });
  },

  // TEMPLATE
  fetchBirthdayTemplate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/get/birthday/templates`, payload)
        .then((response) => {
          if (response) {
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  saveTemplate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/create/template`, payload)
        .then((response) => {
          if (response) {
            commit("ADD_TEMPLATE", response.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  fetchTemplate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/get/template`, payload)
        .then((response) => {
          let data = response.data.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          if (response) {
            commit("ADD_TEMPLATES", data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  deleteTemplate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/delete/template`, payload)
        .then((response) => {
          commit("DELETE_TEMPLATE", response.key);
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  editTemplate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/update/template`, payload.body)
        .then((response) => {
          commit("EDIT_TEMPLATE", {
            key: payload.key,
            data: response.data.data,
          });
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  // scheduled messages
  fetchScheduledMessages({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/scheduled/campaigns/get`, payload)
        .then((response) => {
          if (response) {
            commit("ADD_TEMPLATE", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  deleteScheduledMessages({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/scheduled/campaign/delete/${payload.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  editScheduledMessages({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/scheduled/campaign/update`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
};

export const getters = {
  messages: (state) => state.messages,
  templates: (state) => state.templates,
  birthdayMessages: (state) => state.templates.filter((data) => data.type == 3),
};
