var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('button', {
    attrs: {
      "id": "export"
    }
  }, [_vm._v(" Export ")]);
};
var staticRenderFns = [];
export { render, staticRenderFns };