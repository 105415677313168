import storage from "@/services/storage.js";
var Storage = new storage();
export const state = {
  groupFormData: {},
  contactForm: {},
  contactDetail: {},
  selectedCard: {},
  toIndividual: false,
  toGroup: false,
  fromMessage: false,
  fromTemplate: false,
  selectType: "",
  isFromTemplate: false,
  resetPasswordSuccesData: {},
  fromVoice: false,
  voiceFrom: "",
  quickVoice: false,
  fromSelectSenderIDForVoice: false,
  showSideMenu: false,
  collapseSideMenuItems: localStorage.getItem("collapseSideMenuItems"),
  isInternational: false,
  // State Added by terence
  isFromTemplateWithSpecial: false,
  isScheduledType: false,
  isShowScheduleButtonConfirm: false,
  contactActionsDisabled: true,
  refreshGroups: false
};
export const mutations = {
  SET_FromSelectSenderIDForVoice_FROM(state, payload) {
    state.fromSelectSenderIDForVoice = payload;
  },
  SET_VOICE_FROM(state, payload) {
    state.voiceFrom = payload;
  },
  SET_QUICK_VOICE(state, payload) {
    state.quickVoice = payload;
  },
  SET_FROM_VOICE(state, payload) {
    state.fromVoice = payload;
  },
  SET_IS_FROM_TEMPLATE(state, payload) {
    state.isFromTemplate = payload;
  },
  SET_GROUP_FORMDATA(state, payload) {
    state.groupFormData = payload;
  },
  SET_FROM_MESSAGE(state, payload) {
    state.fromMessage = payload;
  },
  SET_FROM_TEMPLATE(state, payload) {
    state.fromTemplate = payload;
  },
  SET_TO_INDIVIDUAL(state, payload) {
    state.toIndividual = payload;
  },
  SET_TO_GROUP(state, payload) {
    state.toGroup = payload;
  },
  SET_CONTACT_FORMDATA(state, payload) {
    state.contactForm = payload;
  },
  SET_CONTACT_DETAIL(state, payload) {
    state.contactForm = payload;
  },
  REMOVE_STATE_DATA() {
    state.contactForm = {};
  },
  SET_SELECTED_CARD(state, payload) {
    state.selectedCard = payload;
  },
  SET_SELECT_TYPE(state, payload) {
    state.selectType = payload;
  },
  SET_RESET_PASSWORD_SUCCESS(state, payload) {
    state.resetPasswordSuccesData = payload;
  },
  SHOW_SIDE_MENU(state, payload) {
    state.showSideMenu = payload;
  },
  SET_COLLAPSE_MENU_ITEMS(state, payload) {
    state.collapseSideMenuItems = payload;
    localStorage.setItem("collapseSideMenuItems", payload);
  },
  SET_IS_FROM_TEMPLATE_WITH_SPECIAL(state, payload) {
    state.isFromTemplateWithSpecial = payload;
  },
  SET_IS_EDIT_TO_FALSE(state, payload) {
    state.contactForm.isEdit = false;
  },
  SET_BACK_FROM_EDIT(state, payload) {
    state.contactForm.goBackDirectly = payload;
  },
  SET_FROM_SCHEDULED(state, payload) {
    state.isScheduledType = payload;
  },
  SET_IS_INTERNATIONAL(state, payload) {
    state.isInternational = payload;
  },
  SET_IS_SHOW_SCHEDULE_BUTTON_CONFIRM(state, payload) {
    state.isShowScheduleButtonConfirm = payload;
  },
  SET_CONTACT_ACTIONS_DISABLED(state, payload) {
    state.contactActionsDisabled = payload;
  },
  SET_REFRESH_GROUP(state, payload) {
    state.refreshGroups = payload;
  }
};
export const actions = {
  setcollapseSideMenuItems({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_COLLAPSE_MENU_ITEMS", payload);
      resolve(payload);
    });
  },
  setShowSideMenu({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SHOW_SIDE_MENU", payload);
      resolve(payload);
    });
  },
  setFromVoice({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_FROM_VOICE", payload);
      commit("SET_FROM_MESSAGE", false);
      commit("SET_FROM_TEMPLATE", false);
      commit("SET_QUICK_VOICE", false);
      resolve();
    });
  },
  setResetPasswordSuccessMessage({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_RESET_PASSWORD_SUCCESS", payload);
      resolve();
    });
  },
  addGroupFormData({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_GROUP_FORMDATA", payload);
      resolve();
    });
  },
  addContactFormData({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      if (payload.isFromTemplate) {
        commit("SET_IS_FROM_TEMPLATE", true);
      } else {
        commit("SET_IS_FROM_TEMPLATE", false);
      }
      if (payload.quickVoice) {
        commit("SET_QUICK_VOICE", true);
        commit("SET_FROM_TEMPLATE", false);
        commit("SET_FROM_MESSAGE", false);
        commit("SET_FROM_VOICE", false);
      }
      if (payload.fromSelectSenderIDForVoice) {
        commit("SET_FromSelectSenderIDForVoice_FROM", true);
      }
      if (payload.voiceFrom) {
        commit("SET_VOICE_FROM", payload.voiceFrom);
      } else {
        commit("SET_VOICE_FROM", "");
      }
      if (payload.fromTemplate) {
        commit("SET_FROM_TEMPLATE", true);
        commit("SET_FROM_MESSAGE", false);
        commit("SET_FROM_VOICE", false);
        commit("SET_QUICK_VOICE", false);
      }
      if (payload.fromMessage) {
        // console.info("I am here")
        commit("SET_FROM_MESSAGE", true);
        commit("SET_FROM_TEMPLATE", false);
        commit("SET_FROM_VOICE", false);
        commit("SET_QUICK_VOICE", false);
      }
      if (payload.toIndividual) {
        commit("SET_TO_INDIVIDUAL", true);
        commit("SET_TO_GROUP", false);
      }
      if (payload.toGroup) {
        commit("SET_TO_GROUP", true);
        commit("SET_TO_INDIVIDUAL", false);
      }
      if (payload.selectType) {
        commit("SET_SELECT_TYPE", payload.selectType);
      }
      commit("SET_CONTACT_FORMDATA", payload);
      resolve();
    });
  },
  getContactDetail({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_CONTACT_DETAIL", payload);
      resolve();
    });
  },
  removeContactFormData({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("REMOVE_STATE_DATA", payload);
      resolve();
    });
  },
  setSelectCard({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_SELECTED_CARD", payload);
      resolve();
    });
  },
  clerafromSelectSenderIDForVoice({
    commit
  }) {
    return new Promise((resolve, reject) => {
      commit("SET_FromSelectSenderIDForVoice_FROM", false);
      resolve();
    });
  },
  // Added by terence
  setIsFromTemplateWithSpecial({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_IS_FROM_TEMPLATE_WITH_SPECIAL", payload);
      resolve();
    });
  },
  setIsEditToFalse({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_IS_EDIT_TO_FALSE", payload);
      resolve();
    });
  },
  setCancelDirectEdit({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_BACK_FROM_EDIT", payload);
      resolve();
    });
  },
  setFromScheduled({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_FROM_SCHEDULED", payload);
      resolve();
    });
  },
  setIsInternational({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_IS_INTERNATIONAL", payload);
      resolve();
    });
  },
  setIsFromTemplate({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_FROM_TEMPLATE", false);
      resolve();
    });
  },
  setIsShowScheduleButtonConfirm({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_IS_SHOW_SCHEDULE_BUTTON_CONFIRM", payload);
      resolve();
    });
  },
  setContactActionsDisabled({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_CONTACT_ACTIONS_DISABLED", payload);
      resolve();
    });
  },
  setRefreshGroup({
    commit
  }, payload) {
    commit("SET_REFRESH_GROUP", payload);
  }
};
export const getters = {
  showSideMenu: state => state.showSideMenu,
  groupFormData: state => state.groupFormData,
  contactForm: state => state.contactForm,
  contactDetail: state => state.contactDetail,
  selectedCard: state => state.selectedCard,
  toIndividual: state => state.toIndividual,
  toGroup: state => state.toGroup,
  fromMessage: state => state.fromMessage,
  fromTemplate: state => state.fromTemplate,
  resetPasswordSuccesData: state => state.resetPasswordSuccesData,
  fromVoice: state => state.fromVoice,
  voiceFrom: state => state.voiceFrom,
  quickVoice: state => state.quickVoice,
  fromSelectSenderIDForVoice: state => state.fromSelectSenderIDForVoice,
  collapseSideMenuItems: state => state.collapseSideMenuItems,
  getisFromTemplateWithSpecial: state => state.isFromTemplateWithSpecial,
  getisTypeScheduled: state => state.isScheduledType,
  isInternational: state => state.isInternational,
  isShowScheduleButtonConfirm: state => state.isShowScheduleButtonConfirm,
  contactActionsDisabled: state => state.contactActionsDisabled,
  getRefreshGroup: state => state.refreshGroups
};