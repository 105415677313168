// singletonInstanceManager.js
let isActive = false;
export default {
  activate() {
    if (!isActive) {
      isActive = true;
      if (process.env.NODE_ENV === "development") {
        console.log("Instance manager activated");
      }
      return true;
    }
    if (process.env.NODE_ENV === "development") {
      console.log("Instance manager already active");
    }
    return false;
  },
  deactivate() {
    isActive = false;
    if (process.env.NODE_ENV === "development") {
      console.log("Instance manager deactivated");
    }
  },
  isActive() {
    return isActive;
  }
};