import axios from "axios";
import adminAuthHeader from "./auth.header";
class AdminService {
  post(path, data) {
    return axios.post(`${process.env.VUE_APP_ENGINE_URL}/${path}`, data, {
      headers: adminAuthHeader()
    });
  }
  update(path, data) {
    return axios.put(`${process.env.VUE_APP_ENGINE_URL}/${path}`, data, {
      headers: adminAuthHeader()
    });
  }
  delete(path) {
    return axios.delete(`${process.env.VUE_APP_ENGINE_URL}/${path}`, {
      headers: adminAuthHeader()
    });
  }
  get(path) {
    return axios.get(`${process.env.VUE_APP_ENGINE_URL}/${path}`, {
      headers: adminAuthHeader()
    });
  }
}
export default new AdminService();