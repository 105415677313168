export default {
  name: 'alert',
  props: {
    message: {
      required: true
    },
    type: {
      required: true
    }
  }
};