import apiService from "../../services/api.service";
export const state = {
  all_groups: [],
  fetchingData: false
};
export const mutations = {
  SET_GROUP(state, payload) {
    payload.id = payload.group_id;
    payload.name = payload.group_name;
    state.all_groups.unshift(payload);
  },
  SET_API_REQUEST(state, payload) {
    state.fetchingData = payload;
  },
  ADD_GROUP(state, payload) {
    state.all_groups = payload.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  },
  UPDATE_GROUP(state, {
    data,
    key
  }) {
    state.all_groups[key] = data;
    state.all_groups = [...state.all_groups];
  },
  DELETE_GROUP(state, key) {
    state.all_groups.splice(key, 1);
    state.all_groups = [...state.all_groups];
  }
};
export const actions = {
  importContact() {
    //api/import/contacts/
    return new Promise((resolve, reject) => {
      apiService.post(`api/import/contacts/p`, payload).then(response => {
        if (response) {
          commit("SET_GROUP", response.data.data);
        }
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  createGroup({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/create/group`, payload).then(response => {
        if (response) {
          commit("SET_GROUP", response.data.data);
          // Remove Reload when you create a group
          // setTimeout(()=> {
          //     window.location.reload()
          // },100)
        }
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  getGroupCount({
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      apiService.get(`api/count/groups/contact`).then(response => {
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  getGroup({
    commit
  }, {
    id,
    page
  }) {
    return new Promise((resolve, reject) => {
      apiService.get(`api/get/group/${id}?page=${page || 1}`).then(response => {
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  deleteGroup({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/delete/group/${payload.group_id}`).then(response => {
        if (response) {
          commit("DELETE_GROUP", payload.key);
        }
        //   setTimeout(() => {
        //     window.location.reload();
        //   }, 100);
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  updateGroup({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/update/group/${payload.group_id}`, {
        name: payload.name
      }).then(response => {
        if (response) {
          commit("UPDATE_GROUP", {
            data: response.data.data,
            key: payload.key
          });
        }
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  listGroups({
    commit
  }, payload = 1) {
    return new Promise((resolve, reject) => {
      apiService.get(`api/list/groups?page=${payload}`).then(response => {
        if (response && commit) {
          commit("ADD_GROUP", response.data.data.data);
        }
        resolve(response);
      }).catch(err => {
        reject(err);
      });
    });
  },
  voiceGroup({
    commit
  }) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/voice/group`).then(response => {
        if (response) {}
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  exportGroup({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.get(`api/export/group/${payload.id}`).then(response => {
        if (response) {}
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  searchContactInGroup({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.get(`api/search/group/contact?search=${payload.search}&group_id=${payload.group_id}`).then(response => {
        resolve(response);
      }).catch(err => reject(err));
    });
  }
};
export const getters = {
  all_groups: state => state.all_groups,
  fetchingData: state => state.fetchingData
};