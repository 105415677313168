import storage from "@/services/storage.js";

var Storage = new storage();

export const state = {
  /**
   * The current language of the application.
   * @type {'en' | 'fr'}
   */
  currentLanguage: Storage.getSavedState("currentLanguage") || "en",
};

export const getters = {
  currentLanguage: (state) => state.currentLanguage,
};

export const mutations = {
  SET_CURRENT_LANGUAGE(state, payload) {
    state.currentLanguage = payload;
    Storage.saveAndDelete("currentLanguage", payload);
    // set the html lang attribute
    document.documentElement.lang = payload;
  },
};

export const actions = {
  setCurrentLanguage({ commit }, payload) {
    commit("SET_CURRENT_LANGUAGE", payload);
  },
};
