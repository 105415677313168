import Storage from '@/services/storage';
export default function authHeader() {
  let admin = new Storage().getSavedState('auth.admin');
  if (admin) {
    return {
      "Authorization": `Bearer ${admin.token}`
    };
  } else {
    return {};
  }
}