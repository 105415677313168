import "core-js/modules/es.array.push.js";
import apiService from "../../services/api.service";
export const state = {
  contacts: [],
  fromGroup: false,
  singleGroupContacts: {}
};
export const mutations = {
  SET_CONTACT(state, payload) {
    state.contacts.push(payload);
    state.contacts = [...state.contacts];
  },
  ADD_CONTACT(state, payload) {
    state.contacts = payload.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    state.contacts = state.contacts.map(obj => ({
      ...obj,
      active: false
    })).filter(obj => obj.group !== null);
  },
  UPDATE_CONTACT(state, {
    key,
    data
  }) {
    state.contacts[key] = data;
    state.contacts = [...state.contacts];
  },
  DELETE_CONTACT(state, key) {
    state.contacts.splice(key);
  },
  SET_FROM_GROUP(state, payload) {
    state.fromGroup = payload;
  },
  SET_SINGLE_GROUP(state, payload) {
    state.singleGroupContacts = payload;
  },
  SET_SEARCHED_CONTACT(state, payload) {
    state.contacts = payload;
  }
};
export const actions = {
  createContact({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/create/contact/${payload.group_id}`, payload).then(response => {
        if (response) {
          commit("SET_CONTACT", response.data.data);
        }
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  deleteContact({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/delete/contact/${payload.contact_id}`).then(response => {
        if (response.data.message != "Contact Exist in other groups") {
          commit("DELETE_CONTACT", {
            key: payload.key
          });
        }
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  deleteContacts({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/delete/contacts/${payload.contact_ids}`).then(response => {
        if (response.data.message != "Contact Exist in other groups") {
          commit("DELETE_CONTACT", {
            key: payload.key
          });
        }
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  updateContact({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post("api/update/contact/" + payload.id, payload.data).then(response => {
        if (response) {
          commit("UPDATE_CONTACT", {
            key: payload.key,
            data: response.data.data
          });
        }
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  listContacts({
    commit
  }, payload = 1) {
    return new Promise((resolve, reject) => {
      apiService
      // Payload is the lenght you want to get (int)
      .get(`api/list/contacts/?page=${payload}`).then(response => {
        if (response) {
          commit("ADD_CONTACT", response.data.data.data);
          resolve(response);
        }
      }).catch(err => {
        reject(err);
      });
    }).catch(() => {});
  },
  exportContact({
    commit
  }) {
    return new Promise((resolve, reject) => {
      apiService.get(`api/export/contacts`).then(({
        data
      }) => {
        resolve(data);
      }).catch(err => reject(err));
    });
  },
  importContact({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/import/contacts`, payload).then(response => {
        if (response) {}
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  setFromGroup({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_FROM_GROUP", payload);
      resolve();
    });
  },
  setSingleGroupWithContacts({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_SINGLE_GROUP", payload);
      resolve();
    });
  },
  searchContact({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.get(`api/search/contact/?search=${payload.search}`).then(response => {
        if (response) {
          commit("SET_SEARCHED_CONTACT", response.data);
          resolve(response);
        }
      }).catch(err => {
        reject(err);
      });
    }).catch(() => {});
  }
};
export const getters = {
  contacts: state => state.contacts,
  fromGroup: state => state.fromGroup,
  singleGroupContacts: state => state.singleGroupContacts
};