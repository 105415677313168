import apiService from "../../services/api.service";
import loginType from "@/helpers/loginType.js";
export const state = {
  contactsCount: 0,
  groupsCount: 0,
  camapaignCount: 0
};
export const mutations = {
  set_contact_counts(state, payload) {
    state.contactsCount = payload;
  },
  set_groups_counts(state, payload) {
    state.groupsCount = payload;
  },
  set_campaign_count(state, payload) {
    state.camapaignCount = payload;
  }
};
export const getters = {
  contactsCount: state => state.contactsCount,
  groupsCount: state => state.groupsCount,
  camapaignCount: state => state.camapaignCount
};
export const actions = {
  getCampaignCount({
    commit
  }) {
    const url = loginType() == "sms" ? `api/sms/campaigns/history/count` : `api/voice/campaigns/history/count `;
    return new Promise((resolve, reject) => {
      apiService.get(url).then(({
        data: {
          data
        }
      }) => {
        commit("set_campaign_count", data);
        resolve(data);
      }).catch(err => reject(err));
    });
  },
  getContactCounts({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.get(`api/count/contacts`, payload).then(response => {
        commit("set_contact_counts", response.data.data);
        resolve(response);
      }).catch(err => {
        if (err.response.status === 404) {
          commit("set_contact_counts", 0);
        }
        //   reject(err);
      });
    });
  },
  getGroupsCounts({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.get(`api/count/groups`, payload).then(response => {
        commit("set_groups_counts", response.data.data);
        resolve(response);
      }).catch(err => {
        if (err.response.status == 404) {
          commit("set_groups_counts", 0);
        }
      });
    });
  }
};