import apiService from "../../services/api.service";

export const state = {
  celebrants: [],
};
export const mutations = {
  SET_CELEBRANT(state, payload) {
    state.celebrants = payload;
  },
  ADD_CELEBRANT(state, payload) {
    state.celebrants.push(payload);
  },
};
export const actions = {
  enableBirthday({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/toggle/birthday/settings`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  getBirthdaySettings({ commit }) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/get/birthday/settings`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  getTodayCelebrants({ commit }) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/show/today/birthday/celebrants`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  getWeekCelebrants({ commit }) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/show/week/birthday/celebrants`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  getThisMonthCelebrants({ commit }) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/show/month/birthday/celebrants `)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  birthDaySettings({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/store/birthday/settings`, payload)
        .then((response) => {
          if (response) {
            commit("ADD_CELEBRANT", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
};

export const getters = {
  celebrants: (state) => state.celebrants,
};
