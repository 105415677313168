import AdminService from "@/services/admin.service";
import moment from "moment";
import Storage from "../../../services/storage";
const storage = new Storage();
export const state = {
  industryReporting: [],
  senderNamesReport: [],
  qualityRoutesReport: [],
  messageType: [],
  messageSummaryReport: [],
  detbors: [],
  bundleHistory: [],
  walletHistory: [],
  financeGraph: [],
  chartAccounts: [],
  paymentAggregates: [],
  specificDebtorList: [],
};

export const mutations = {
  SET_FINANCE_GRAPH(state, payload) {
    state.financeGraph = payload;
  },
  SET_INDUSTRY_REPORTING(state, payload) {
    state.industryReporting = payload;
  },
  SET_SENDER_NAME_REPORT(state, payload) {
    state.senderNamesReport = payload;
  },
  SET_QUALITY_ROUTES_REPORT(state, payload) {
    state.qualityRoutesReport = payload;
  },
  SET_MESSAGE_TYPE(state, payload) {
    state.messageType = payload;
  },
  SET_WALLET_HISTORY(state, payload) {
    state.walletHistory = payload;
  },
  SET_DEBTORS(state, payload) {
    state.detbors = payload;
  },
  SET_BUNDLE_HISTORY(state, payload) {
    state.bundleHistory = payload;
  },
  sET_MESSAGE_HISTORY(state, payload) {
    state.messageSummaryReport = payload;
  },
  SET_CHART_ACCOUNTS(state, payload) {
    state.chartAccounts = payload;
  },
  SET_PAYMENT_AGGREGATES(state, payload) {
    state.paymentAggregates = payload;
  },
  SET_SPECIFIC_DEBTOR_LIST(state, payload) {
    state.specificDebtorList = payload;
  },
};
export const actions = {
  getFinanceGraph({ commit }) {
    const country = storage.getSavedState("auth.admin").country;
    return new Promise((resolve, reject) => {
      AdminService.get(`api/finance/graph?country=${country}`)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_FINANCE_GRAPH", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getIndustryReporting({ commit }) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/industry/report`)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_INDUSTRY_REPORTING", data[0]);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getSenderNamesReport({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/sender/name/history?page=${payload.page}`)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_SENDER_NAME_REPORT", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getQualityRoutesReport({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/report/otp/services?from=${payload.from}&to=${payload.to}`
      )
        .then((data) => {
          if (data) {
            commit("SET_QUALITY_ROUTES_REPORT", data);
          }
          resolve(data.data);
        })
        .catch((err) => reject(err));
    });
  },
  getMessageTypes({ commit }, payload) {
    const country = storage.getSavedState("auth.admin").country;
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/message/type/report?country=${country}&from=${payload.from}&to=${payload.to}`
      )
        .then(({ data: { data } }) => {
          commit("SET_MESSAGE_TYPE", data);
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getMessageSummarry({ commit }, payload) {
    const country = storage.getSavedState("auth.admin").country;
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/message/summary/report?country=${country}&from=${payload.from}&to=${payload.to}`
      )
        .then(({ data: { data } }) => {
          resolve(data);
          commit("sET_MESSAGE_HISTORY", data);
        })
        .catch((err) => reject(err));
    });
  },
  getBundleHistory({ commit }, payload) {
    const country = storage.getSavedState("auth.admin").country;
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/bundle/history?to=${payload.to}&from=${payload.from}&country=${country}`
      )
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_BUNDLE_HISTORY", data[0]);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  exportBundleHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/export/all/bundle/history?from=${payload.from}&to=${payload.to}`
      )
        .then((data) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getWalletHistory({ commit }) {
    const country = storage.getSavedState("auth.admin").country;
    return new Promise((resolve, reject) => {
      AdminService.get(`api/wallet/deposit/history?country=${country}`)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_WALLET_HISTORY", data[0]);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getDebtorsList({ commit }) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/debtors`)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_DEBTORS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  businessChasis({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/business/chasis/report`, payload)
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  salesMarket({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/sales-marketing/report`, payload)
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getChartAccounts({ commit }) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/get/chart/accounts`)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_CHART_ACCOUNTS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  addChartAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/save/chart/accounts`, payload)
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  updateChartAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/update/chart/accounts`, payload)
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getPaymentAggregates({ commit }) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/get/payment/gateway`)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_PAYMENT_AGGREGATES", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  addPaymentAggregates({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/save/payment/gateway`, payload)
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  updatePaymentAggregates({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/update/payment/gateway`, payload)
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  clearDebitors({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/clear/debt`, payload)
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  deleteDebitors({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/delete/debt`, payload)
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getSpecificDebtor({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/debtors/${payload}`)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_SPECIFIC_DEBTOR_LIST", data);
            resolve(data);
          }
        })
        .catch((err) => reject(err));
    });
  },
  deleteSpecificDebtor({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/delete/debt/child`, payload)
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },

  fetchTheoBotReports({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/theobot/${payload.report}/campaigns/history?page=${payload.page}&dateFrom=${payload.date[0]}&dateTo=${payload.date[1]}`
      )

        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },

  fetchTheoBotSenderIDs({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/theobot/sender-ids/get?page=${payload.page}&dateFrom=${payload.date[0]}&dateTo=${payload.date[1]}`
      )
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  fetchTheoBotWalletHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/theobot/wallet/history?page=${payload.page}&dateFrom=${payload.date[0]}&dateTo=${payload.date[1]}`
      )
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  fetchTheoBotBundleHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/theobot/bundle/purchase/history?page=${payload.page}&dateFrom=${payload.date[0]}&dateTo=${payload.date[1]}`
      )
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  fetchTheoBotReportChart({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/theobot/report/counts?dateFrom=${payload.dateFrom}&dateTo=${payload.dateTo}`
      )
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
};

export const getters = {
  financeGraph: (state) => state.financeGraph,
  industryReporting: (state) => state.industryReporting,
  detbors: (state) => state.detbors,
  bundleHistory: (state) => state.bundleHistory,
  walletHistory: (state) => state.walletHistory,
  senderNamesReport: (state) => state.senderNamesReport,
  messageSummaryReport: (state) => state.messageSummaryReport,
  messageSummaryData: (state) => {
    let messageSent = [];
    let creditUsed = [];
    let messageLabel = [];
    state.messageSummaryReport.forEach((message) => {
      creditUsed.push(message["Credit Used"]);
      messageSent.push(message["Messages Sent"]);
      messageLabel.push(moment(message.day).format("LL"));
    });
    return [
      messageLabel,
      [
        {
          name: "Messages Sent",
          data: messageSent,
        },
        {
          name: "Credit Used",
          data: creditUsed,
        },
      ],
    ];
  },
  messageTypesCount: (state) => {
    let totalContacts = [];
    state.messageType.forEach((type) => totalContacts.push(type.contacts));
    return [
      {
        name: "Contacts",
        data: totalContacts,
      },
    ];
  },
  messageTypeCategories: (state) => {
    let types = [];
    state.messageType.forEach((type) => types.push(type.message_type));
    return types;
  },
  industryLabels: (state) => {
    return state.industryReporting.map((data) => data.industry_name);
  },
  industriesCount: (state) => {
    let industriesNumb = [];
    state.industryReporting.forEach((ind) => {
      industriesNumb.push(ind.individual.length);
    });
    return industriesNumb;
  },
  industryUsersCount: (state) => {
    let sum = 0;
    state.industryReporting.forEach((industry) => {
      sum += industry.individual.length;
    });
    return sum;
  },
  singleIndustry: (state) => (id) => {
    if (!state.industryReporting) return {};
    return state.industryReporting.find((ind) => ind.id == id);
  },
  chartAccounts: (state) => state.chartAccounts,
  paymentAggregates: (state) => state.paymentAggregates,
  specificDebtor: (state) => state.specificDebtorList,
  businessChasis: (state) => state.businessChasis,
};
