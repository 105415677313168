import Storage from '@/services/storage';
export default function authHeader() {
  let storage = new Storage();
  let authUser = storage.getSavedState('token');
  if (authUser) {
    return {
      "Authorization": `Bearer ${authUser}`
    };
  } else {
    return {};
  }
}