import { last } from "lodash";
import SecureLS from "secure-ls";
import simpleBrowserFingerprint from "simple-browser-fingerprint";
const securityOptions = {
  encodingType: "",
  encryptionSecret: undefined,
  isCompression: false
};
if (process.env.NODE_ENV === "production") Object.assign(securityOptions, {
  encodingType: "aes",
  encryptionSecret: simpleBrowserFingerprint(),
  isCompression: true
});
var ls = new SecureLS(simpleBrowserFingerprint);
export default class Storage {
  getSavedState(key) {
    let saveState = null;
    if (ls.get(key)) {
      saveState = ls.get(key);
    }
    return saveState;
  }
  saveCurrentUser(key, data) {
    if (typeof data === 'object') {
      ls.set(key, data);
    }
  }
  saveAndDelete(key, state) {
    if (ls.get(key)) {
      ls.remove(key);
    }
    ls.set(key, state);
  }
  saveState(key, state) {
    if (!ls.get(key)) {
      ls.set(key, state);
    }
  }
  clearSavedData(key) {
    if (ls.get(key)) {
      ls.remove(key);
    }
  }
  clearAllSavedData() {
    ls.removeAll();
  }
}