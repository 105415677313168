<template>
  <div id="app">
    <RouterView />
  </div>
</template>
<script>
  import appConfig from "@/app.config";
  export default {
    page: {
      // All subcomponent titles will be injected into this template.
      titleTemplate(title) {
        title = typeof title === "function" ? title(this.$store) : title;
        return title ? `${title} | ${appConfig.title}` : appConfig.title;
      },
    },
    mounted() {
      const response = fetch("https://ip2c.org/s").then(async () => {
        if (response.ok) {
          const data = await response.text();
          const [, code] = data.split(";");
          // set language based on country code CI || SN is fr else en
          if (code === "CI" || code === "SN") {
            this.$root.$i18n.locale = "fr";
            document.documentElement.lang = "fr";
          } else {
            this.$root.$i18n.locale = "en";
            document.documentElement.lang = "en";
          }
          this.$store.dispatch(
            "language/setCurrentLanguage",
            code === "CI" || code === "SN" ? "fr" : "en"
          );
          return code;
        }
      });
    },
  };
</script>

<style lang="scss">
  @import url("./styles/table.scss");
  @import url("./styles/content.scss");

  @font-face {
    font-family: "Graphik Regular";
    src: url("./assets/Graphik-Font-Family/GraphikRegular.otf");
  }

  /* 
FILE UPLOADER
*/
  .__select__message__template {
    width: 90%;
    padding-top: 2em;
    padding-bottom: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-upload__text em {
    color: #f7921c;
  }

  .__upload__audio__file {
    font-style: normal;
    font-weight: 500;
    font-size: 16.3721px;
    line-height: 20px;
    text-align: center;
    color: #575761;
  }

  .file_type {
    font-style: normal;
    font-weight: normal;
    font-size: 12.7338px;
    line-height: 20px;
    text-align: center;
    color: #575761;
  }

  .drag_file {
    font-style: normal;
    font-weight: normal;
    font-size: 12.7338px;
    line-height: 20px;
    color: #575761;
  }

  .el-upload__text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 2em;
    height: 230px;
  }

  .el-upload__text img {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .el-upload__text:hover {
    border-color: #f7921c !important;
  }

  // top retailer bar
  .__retailer__bar {
    width: 100%;
    height: 42px;
    background: #2e42a5;
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: #ffffff;
    padding-top: 10px;
    padding-right: 2em;
    text-align: center;
    position: fixed;
    top: 0px;
    z-index: 100;

    button {
      border: none;
      outline: none;
      background: transparent;
      color: #f7921c;
    }

    p {
      text-align: center !important;
      display: inline-block;
    }

    .close_retailer {
      display: inline-block;
      float: right;
    }
  }

  #app {
    font-family: "Graphik Regular";
  }

  body {
    margin: 0;
    margin: 0px;
    background: #fafafa !important;
  }

  //CUSTOM MNOTIFY GLOBAL CSS
  .description {
    color: #f7921c;
  }

  .success-green {
    color: #36d962;
  }

  .violet-deep {
    color: #f7921c;
  }

  .violet-light {
    color: #f7921c;
  }

  .yellow {
    color: #f0e930;
  }

  .light-blue {
    color: #30a1f0;
  }

  .gray {
    color: #575761;
  }

  .text {
    color: #484a4f;
  }

  .link {
    color: #f7921c;
  }

  .component_inner_wrapper {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .side-image-background {
    background-image: url("./assets/tech.jpg");
    width: 100%;
    background-position: center;
    object-fit: cover;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    white-space: nowrap;
  }

  // form
  .form_text_container {
    padding-top: 40px;
  }

  .main_form_wrapper {
    padding-right: 120px;
    width: 100%;
    align-items: center !important;
    background-color: #fff;
    justify-content: flex-end;
    padding-top: 70px;
  }

  .form_container {
    width: 380px !important;
    padding: 0 !important;
    margin-right: 3em;
    display: inline-block;
  }

  .logo_container {
    padding-top: 30px;
    padding-left: 20px;
    position: fixed;
  }

  .text_right_corner {
    color: #575761;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
  }

  .account_link {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    color: #f7921c;
    padding-left: 8px;
    text-decoration: none;
    text-transform: capitalize;
  }

  .account_link:hover {
    color: #f7921c;
  }

  .form_action_btn {
    height: 50px;
    width: 380px;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    outline: none;
    border: none;
    background-color: #f7921c;
    margin-top: 16px;
  }

  .form_link {
    color: #f7921c;
    float: right;
  }

  form {
    padding: 0px;
    margin: 0px;
  }

  .form_title {
    font-family: "Graphik Regular";
    font-size: 22px;
    font-weight: bolder;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #575761;
  }

  hr.form_line {
    background-color: #f3f3f4;
    width: 100%;
    opacity: 0.5;
  }

  .form_input_label {
    display: block;
    color: #575761;
    font-family: "Graphik Regular";
    font-size: 16px;
    padding-left: 10px;
    text-align: left;
    padding-bottom: 10px;
    font-weight: bolder;
    line-height: 15px;
    margin-top: 28px;
    margin-bottom: 5px;
  }

  .form_input_container input,
  .form_input_container select {
    // border:none;
    // outline:none;
    background: #f3f3f4;
    height: 48px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    padding: 13px;
  }

  .form_input_container textarea {
    background: #f3f3f4;
    border-radius: 5px;
    font-size: 14x;
    font-weight: 500;
    padding: 13px;
  }

  // input {
  //   padding:13px;
  // }
  .form_input_long {
    width: 100%;
  }

  .form_action_btn {
    width: 100%;
    background-color: #f7921c;
    outline: none;
    border: none;
    color: #fff;
    height: 48px;
  }

  .float-right {
    float: right;
  }

  .float-left {
    float: left;
  }

  .form_country_code_select {
    width: 25%;
  }

  .form_country_code_phone_number {
    width: 70%;
    float: right;
  }

  // RESET PASSWORD TEST
  .reset_password_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #575761;
  }

  .return_to_login {
    padding-top: 32px;
  }

  .return_to_login a {
    color: #f7921c;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    line-height: 15px;
    letter-spacing: 0em;
  }

  .return_to_login a:hover {
    color: #f7921c;
  }

  // reset password
  .reset_pin {
    width: 34px;
    height: 40px;
  }

  .input_text_padding_right {
    margin-right: 17px !important;
  }

  .input_text_padding_right_long {
    margin-right: 45px !important;
  }

  // SIGN UP
  .terms_and_conditons_container {
    margin-top: 28px;
    margin-bottom: 20px;
  }

  .terms {
    font-size: 12.5px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
  }

  #mNotifyTerms[type="checkbox"] {
    display: inline;
    height: 17px;
    width: 17px;
    border-radius: 3px;
    margin-right: 15px !important;
    outline: none;
    border: none;
  }

  #mNotifyTerms[type="checkbox"]:checked + label::after {
    background: #f7921c !important;
  }

  #mNotifyTerms_link {
    font-size: 12.5px;
    text-decoration: none;
    color: #f7921c;
  }

  #mNotifyTerms_link:hover {
    color: #f7921c;
  }

  // VERIFY ACCOUNT
  .pin__input__container {
    display: flex;
  }

  .veriy_account_input_text_padding_right {
    margin-right: 24px !important;
  }

  .verify_pin,
  .verify_pin input {
    width: 40px;
    height: 48px;
  }

  .wrong_number button,
  .resend-code {
    padding-bottom: 20px;
    color: #f7921c;
    font-weight: bolder;
    font-size: 16px;
    padding: 0px;
    background: transparent;
    border: none;
    outline: none;
  }

  .did_not_receive_code,
  .call_instead {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  .resend_code {
    font-weight: bolder;
    font-size: 16px;
    padding-left: 1em;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .resend_code a:hover {
    color: #f7921c;
  }

  .resend_code a {
    text-decoration: none;
    color: #f7921c;
  }

  .did_not_receive_code {
    padding-top: 1.5em;
  }

  // receive auth code
  .enter_auth_code {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #575761;
  }

  .message_type {
    width: 40%;
    height: 50px;
    padding: 13px 20px;
    color: #575761;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
  }

  .message_type:nth-child(1) {
    margin-right: 40px;
  }

  // REFERE FRIEND
  .share_link_label {
    font-family: "Graphik Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    color: #575761;
  }

  .share_your_code {
    //styleName: Text/body/bold;
    font-family: "Graphik Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
  }

  .share_icons button {
    width: 45px;
    height: 45px;
    outline: none;
    border: none;
    background-color: transparent;
    margin-right: 27px;
    background-color: #f3f3f4;
    border-radius: 5px;
  }

  .form_input_share_container {
    background-color: #f3f3f4 !important;
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
  }

  .form_input_share_container input {
    border: none;
    outline: none;
    background-color: #f3f3f4;
    width: 86%;
  }

  .form_input_share_container button {
    width: 40px;
    height: 40px;
    background: #f7921c;
    outline: none;
    border: none;
    border-radius: 5px;
  }

  // share referal code 2
  .share_referal_header {
    height: 118px;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    background-color: #f3f3f4;
    align-items: center;
    padding-top: 30px !important;
    margin-bottom: 100px !important;
  }

  .share_referal_header_title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  .share_referal_header_text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
  }

  .share_your_code_2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    padding-top: 20px;
  }

  .share_referal_form_wrapper {
    width: 380px !important;
    padding: 0 !important;
    display: inline-block;
  }

  // MNOTIFY FORM
  #mnotify__form {
    width: 100%;
    display: flex;
    margin: 0px;
    padding: 0px;
    background-color: #fff !important;
  }

  #mNotify__form_image_container {
    width: 40%;
    height: 100%;
  }

  #mNotify_form_submit_btn {
    width: 100%;
    height: 48px;
  }

  #mNotify__form_fields_container,
  #mNotify__signin__form_fields_container {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  #mNotify__signin__form_fields_container {
    height: 100vh;
  }

  .mnotitfy__form_image {
    background-image: url("./assets/tech.jpg");
    width: 100%;
    height: 1150px;
    background-position: center;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .mNotify__form_fields_inner_container {
    width: 380px;
  }

  .mnotify__form_upper_link {
    text-align: center;
    padding-top: 38px;
  }

  .mnotify_form__inner__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  // .page__padding{
  //   margin-top:3em;
  //   margin-right:3em;
  //   margin-left:3em;
  // }
  //dashboard card
  .mnotify-card {
    background: #ffffff;
    border-radius: 5px;
    margin-bottom: 2em;
    padding-right: 24px;
    padding-left: 24px;
  }

  .mnotify-card-header {
    padding-top: 17px;
    padding-bottom: 17px;
    border-bottom: 1px solid #f3f3f4;
  }

  .mnotify-card-header h3 {
    margin: 0px;
    padding: 0px;
  }

  .mnotify-card-header-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-transform: capitalize;
    color: #575761;
  }

  .mnotify-card-body {
    width: 100%;
    height: 100%;
  }

  // profile settings form
  .profile-settings-form {
    margin-left: 40px;
    margin-top: 3em;
  }

  .profile-description {
    font-weight: normal;
    font-size: 10px;
    line-height: 11px;
    color: #484a4f;
  }

  .profile-settings-section-header {
    display: flex;
  }

  .profile-see-more {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #484a4f;
  }

  // GLOBAL EMPTY STATE HEADER
  .empty_header {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #051c50;
  }

  .empty_text_sm {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #778199;
  }

  .btn:focus,
  .btn:active {
    outline: none !important;
    box-shadow: none !important;
  }

  // MODAL
  // .modal .modal-lg{
  //   width:698px!important;
  // }
  .modal-header {
    border-bottom: 1px solid #f3f3f4;
    margin-right: 15px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    padding-left: 0 !important;
  }

  .contact_btn_green {
    background: #33c481;
  }

  .modal__footer__btns {
  }

  .__add__new__data {
    float: right;
    outline: none;
    border: none;
    background-color: transparent;
    color: #f7921c;
    font-size: 16px;
    font-weight: 600;
  }

  .__close__success__modal {
    outline: none;
    border: none;
    width: 81px;
    height: 41px;
    border-radius: 5px;
    background-color: #f7921c;
    box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.1);
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    margin-bottom: 2em;
  }

  /*************** GROUPS, CONTACTS, MESSAGES GLOBAL STYLES ********/
  .___back__btn {
    width: 78px;
    height: 47.9px;
    background: #f5f5f5;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 159.3%;
    text-align: center;
    color: #979797;
    border: none;
    outline: none;
  }

  .schedule_message____ {
    height: 47.9px;
    background: #f5f5f5;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 159.3%;
    text-align: center;
    color: #979797;
    border: none;
    outline: none;
    margin-right: 0.5em;
    padding-left: 0.7em;
    padding-right: 0.7em;
  }

  .___btn__shedule {
    width: 113px;
    height: 47.9px;
    left: 396px;
    top: 624.64px;
    background: #f5f5f5;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 159.3%;
    text-align: center;
    color: #979797;
  }

  .__message__form__text {
    display: flex;
  }

  .__message__form__text button {
    border: none;
    outline: none;
    background-color: transparent;
    color: #f7921c;
    font-size: 12px;
  }

  .cancel {
    background: #f5f5f5;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    padding: 0.5em 2em;
    text-align: center;
    color: #9e9ea7;
    outline: none;
    border: none;
    height: 47.9px;
  }

  .__cancel {
    width: 234.53px;
    height: 47.9px;
    background: #f5f5f5;
    border-radius: 5px;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #9e9ea7;
  }

  .__add__group_contact__btn {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 159.3%;
    text-align: center;
    color: #f7921c;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 10px;
    outline: none;
    border: none;
    background: transparent;
  }

  /* search and filter */
  .search_filter {
    width: 100%;
    display: flex;
    justify-content: flex-end !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    height: 60px !important;
  }

  .search_filter input {
    background: #f3f3f4;
    border-radius: 5px;
    height: 40px;
    width: 265px;
  }

  #__filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 20px;
    width: 123px;
    height: 40px;
    background: #f5f5f5;
    border-radius: 5px;
    outline: none;
    border: none;
    color: #979797;
    text-align: center;
    margin-left: 1em;
  }

  .__error {
    color: #ff5656;
    font-size: 12px;
  }

  /* CONTACT GROUPS, LIST */
  .list_contantainer {
    width: 100%;
    height: 100%;
  }

  .group_list {
    background-color: inherit;
    width: 100%;
    margin: 0px;
    padding: 0px;
    list-style: none;
  }

  .group_list_item {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-bottom: 1px solid #f3f3f4;
  }

  .__inside_list__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .group_list_item p {
    margin: 0px !important;
    padding: 0px !important;
  }

  /* --- lodaing state --- */
  /* REPORTING NAVIGATION BUTTON */
  .__reporting_nav__container {
    width: 320px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
  }

  .__reporting__nav {
    outline: none;
    background: #f3f3f4;
    border: 1px solid #d4d8e2 !important;
    box-sizing: border-box;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-transform: capitalize;
    color: #484a4f;
    width: 148px;
    height: 40px;
  }

  .__reporting__nav_active {
    background-color: rgba(247, 146, 28, 0.1) (109, 34, 242, 0.1) !important;
    color: #f7921c !important;
    border: 1px solid #f7921c !important;
  }

  .back__btn {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-transform: capitalize;
    color: #f7921c;
    margin-right: 1em;
    outline: none;
    border: none;
    background: transparent;

    img {
      margin-right: 1em;
    }
  }

  /******* select sender id *************/
  #__create {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ._select {
      width: 65%;
      margin-right: 1em;
    }

    .button {
      width: 35%;

      button {
        width: 100%;
        background-color: #f7921c;
        color: #fff;
        border: 1px solid #f7921c;
        outline: none;
        font-size: 12px;
        height: 48px;
        margin-top: 2em;
      }
    }
  }

  .export {
    width: 80px;
    height: 48px;
    box-sizing: border-box;
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #ffffff;
    background: #f7921c;
    border-radius: 5px;
    border: none;
    outline: none;
  }

  @media screen and (max-width: 500px) {
    #__create {
      flex-direction: column;

      ._select {
        width: 100%;
        margin-right: 0px !important;
      }

      .button {
        width: 100%;

        button {
          width: 100%;
          margin-top: 0.5em;
          margin-bottom: 1em;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    #mNotify__form_image_container {
      display: none;
    }

    #mNotify__form_fields_container,
    #mNotify__signin__form_fields_container {
      width: 100%;
    }

    .mnotify_form__inner__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .profile-settings-form {
      margin-left: 0px;
      margin-top: 2em;
    }

    .modal .modal-lg {
      width: 90% !important;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    // .modal .modal-lg{
    //     min-width:698px!important;
    //   }
  }

  @media screen and (max-width: 500px) {
    .mNotify__form_fields_inner_container {
      width: 300px !important;
    }

    .mNotify__form_fields_inner_container {
      width: 80% !important;
    }

    // .modal .modal-lg{
    //   width:95%!important;

    // }
  }

  @media screen and (max-width: 768px) {
    #mNotify__form_fields_container {
      background-image: url("./assets/tech.jpg");
      width: 100%;
      background-position: center;
      object-fit: cover;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  @media screen and (max-width: 992px) {
    .page_medium_top_margin {
      margin-top: 2em;
    }
  }

  // Affect all scrollbars
  /* width */
  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  // body,
  // html {
  //   height: 100vmin;
  //   overflow: hidden;
  // }

  .mnotify__main_dashboard_main_content_side_nav::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 768px) {
    body,
    html {
      height: auto;
      overflow: auto;
    }
  }

  .country-selector label {
    font-size: 11px !important;
    line-height: 10px;
  }

  .country-selector__toggle {
    line-height: 10px;
  }

  .country-selector__country-flag * {
    position: relative;
    bottom: 3px;
  }

  .iti-flag-small {
    position: relative;
  }

  // #MazPhoneNumberInput-18_phone_number {
  //   border-radius: 5px !important;
  // }
  .input-tel__label {
    font-size: 10px !important;
    padding: 0 !important;
    line-height: 9px;
  }

  .input-tel__input {
    padding: 3px 10px !important;
  }

  .el-dialog__headerbtn * {
    color: black !important;
  }

  .el-dialog--center {
    border-color: transparent;
    border-radius: 5px !important;
  }

  .banner {
    width: 100vw !important;
    height: 12vh !important;

    position: absolute !important;
    top: 0 !important;
    background-color: #3abf5e !important;
  }

  .banner p {
    color: #fff !important;
    font-size: large;
  }

  .banner i {
    color: #fff !important;
  }

  .banner i:nth-of-type(2) {
    font-weight: 900 !important;
  }

  font {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  button {
    width: fit-content;
    text-align: center;
  }
</style>
