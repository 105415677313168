import AdminService from "@/services/admin.service";
export const state = {
  sms_voice_rates: []
};
export const mutations = {
  SET_SMS_VOICE_RATE(state, payload) {
    payload.forEach(element => {
      element.amount = Number(element.amount);
    });
    state.sms_voice_rates = payload;
  }
};
export const actions = {
  sendSMSVoiceRate({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/store/bundle/rate`, payload).then(response => {
        if (response) {
          resolve(response);
        }
      }).catch(err => reject(err));
    });
  },
  getSMSVoiceRate({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/get/bundle/rates?country=${payload.country || ""}`).then(response => {
        if (response) {
          commit("SET_SMS_VOICE_RATE", response.data.data);
          resolve(response);
        }
      }).catch(err => reject(err));
    });
  },
  deleteRate({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/delete/bundle/rate`, payload).then(response => {
        if (response) {
          resolve(response);
        }
      }).catch(err => reject(err));
    });
  }
};
export const getters = {
  getSMSVoiceRates: state => state.sms_voice_rates
};