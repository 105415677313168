import "core-js/modules/es.array.push.js";
import apiService from "../../services/api.service";
export const state = {
  voiceNotes: [],
  request: ''
};
export const mutations = {
  SET_GROUP(state, payload) {
    state.all_groups.push(payload);
  },
  SET_VOICE_NOTES(state, payload) {
    state.voiceNotes.push(payload);
  },
  DELETE_VOICE(state, key) {
    state.voiceNotes.splice(key, 1);
  },
  REQUEST_DID(state, payload) {
    state.request.push(payload);
  }
};
export const actions = {
  getVoiceNotes({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/voice-notes/get`, payload).then(response => {
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  getVoiceTemplates({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.get(`api/show/voice/template`, payload).then(response => {
        commit("SET_VOICE_NOTES", response.data);
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  voiceQuick({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/voice/quick`, payload).then(response => {
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  voiceGroup({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/voice/group`, payload).then(response => {
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  quickExcel({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/voice/excel`, payload).then(response => {
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  saveVoiceNote({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/voice-note/save`, payload).then(response => {
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  deleteVoiceNote({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/voice-note/delete/`, {
        voice_note_id: payload.voice_note_id
      }).then(response => {
        commit("DELETE_VOICE", {
          key: payload.key
        });
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  // getVoiceNotes({commit}) {
  //     return new Promise( (resolve, reject) => {
  //         apiService.get(`api/voice-notes/get`)
  //             .then( response => {
  //                 resolve(response)
  //             }).catch( err => reject(err))
  //     })
  // },
  getScheduledCampaigns({
    commit
  }) {
    return new Promise((resolve, reject) => {
      apiService.get(`api/scheduled/voice/campaigns/get`).then(response => {
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  requestDid({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/request/voice/did`, payload).then(({
        data: {
          message
        }
      }) => {
        resolve(message);
      }).catch(err => reject(err));
    });
  },
  deleteVoiceMessage({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/scheduled/voice/campaign/delete/${payload.id}`).then(response => {
        resolve(response);
      }).catch(err => reject(err));
    });
  },
  editScheduledVoiceMessages({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      apiService.post(`api/scheduled/voice/campaign/update`, payload).then(response => {
        resolve(response);
      }).catch(err => reject(err));
    });
  }
};
export const getters = {
  voiceNotes: state => state.voiceNotes,
  request: state => state.request
};