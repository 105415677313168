import apiService from "../../services/api.service";
import countryCode from "../../helpers/countryCode";
import Storage from "@/services/storage";
import axios from "axios";
const storage = new Storage();
localStorage.setItem("needsToResetPassword", 0);
import { Message } from "element-ui";

export const state = {
  currentUser: storage.getSavedState("currentUser"),
  countryCode: countryCode(),
  userProfile: {},
  isFirstTimeLogin: false,
  isFirstTimeUser: false,
  profileUpdated: false,
  resetWindowVisible: false,
  numberToVerify: localStorage.getItem("TempActivateNumber") || null,
  disallowedUsers: [31227, 17558, 17559, 17568],
};

// get current user from local storage

export const mutations = {
  // State of whether reset window is visible
  SET_RESET_WINDOW_VISIBLE(state, payload) {
    state.resetWindowVisible = payload;
  },
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue;
    //newValue.access_level = 4
    if (newValue.access_level == 4) {
      storage.saveState("auth.admin", newValue);
    } else {
      storage.saveAndDelete("currentUser", newValue);
      storage.saveState("token", newValue.token);
    }
  },
  SET_USER_PROFILE(state, payload) {
    state.userProfile = payload;
  },
  LOGOUT(state) {
    storage.clearSavedData("currentUser");
    storage.clearSavedData("token");
    state.currentUser = null;
    localStorage.setItem("userName", null);
    storage.clearSavedData("source");
  },
  UPDATE_CURRENTUSER(state, payload) {
    // Store token here
    let oldToken = storage.getSavedState("currentUser");
    payload.country = oldToken.country;
    if (
      oldToken.country === `Cote d'Ivoire` ||
      oldToken.country === "CI" ||
      oldToken.country_code === "CI"
    ) {
      oldToken.country = "CI";
    } else {
      oldToken.country = "GH";
    }
    payload.token = oldToken.token;
    payload.country = oldToken.country;

    state.currentUser = payload;

    // Set New Names Here
    localStorage.setItem(
      "userName",
      `${payload.firstname} ${payload.lastname}`
    );
    storage.saveAndDelete("currentUser", payload);
  },
  SET_IS_FIRST_TIME_LOGIN(state, payload) {
    if (payload.type == "register") {
      storage.saveAndDelete("isFirstTimeLogin", {
        isFirstTimeLogin: true,
        from: "register",
      });
    } else {
      storage.saveAndDelete("isFirstTimeLogin", {
        isFirstTimeLogin: true,
        from: "login",
      });
    }
  },
  SET_NUMBER_TO_VERIFY(state, payload) {
    state.numberToVerify = payload;
  },
};

export const actions = {
  // init({ state, dispatch }) {
  //   // setDefaultAuthHeaders(state)
  //   dispatch("validate");
  // },
  voiceActivation({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/voice/otp", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  toggleUserStatus({ commit }) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/toggle/login/status")
        .then((response) => {
          commit("REMOVE_FIRST_TIME_USER");
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  verificationByCall() {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/voice/otp", data)
        .then((response) => {
          resolve(response.data.message);
        })
        .catch((err) => reject(err));
    });
  },
  createRetailerAccount({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/reseller/request", data)
        .then((response) => {
          resolve(response.data.message);
        })
        .catch((err) => reject(err));
    });
  },
  register({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/register", data)
        .then((response) => {
          if (response) {
            commit("SET_CURRENT_USER", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  login({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/login", data)
        .then(async (response) => {
          if (response) {
            const omniData = await dispatch(
              "parseOmniChannel",
              response.data.data
            );
            if (omniData) {
              const onmnOK = await dispatch("subscribeToOmniChannel", omniData);
              if (onmnOK) {
                commit("SET_CURRENT_USER", response.data.data);
                storage.saveState("source", "omniChannel");
                sessionStorage.setItem(
                  "userName",
                  `${response.data.data.name}`
                );
              }
              Message.success({
                message:
                  "Your account has been successfully linked to the mNotify Omni Channel!",
                type: "success",
                duration: 5000,
              });
              resolve({ data: { data: onmnOK } });
            } else {
              commit("SET_CURRENT_USER", response.data.data);
              sessionStorage.setItem("userName", `${response.data.data.name}`);
              storage.saveState("source", "normal");
              resolve(response);
            }
          }
        })
        .catch((err) => reject(err));
    }).catch((err) => {
      throw err;
    });
  },
  getResetPasswordVerificationCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/validate/otp/reset", data)
        .then((response) => {
          if (response) {
            commit("SET_CURRENT_USER", response.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  // New Function That sends over new password
  sendNewPasswordToServer({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/validate/password/reset", data)
        .then((response) => {
          if (response) {
            commit("SET_CURRENT_USER", response.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  resetPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/password/reset", data)
        .then((response) => {
          if (response) {
            commit("SET_CURRENT_USER", response.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  resendActivationCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/activation/code/resend", data)
        .then((response) => {
          if (response) {
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  activateAccount({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/activate/account", data)
        .then((response) => {
          if (response) {
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  reActivateAccount({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/reactivate/account", data)
        .then((response) => {
          if (response) {
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  updatePassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/update/password", data)
        .then((response) => {
          if (response) {
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  updatePhone({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/update/phone", data)
        .then((response) => {
          if (response) {
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  validatePhone({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/update/phone/validate", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  submitIndividualProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/individual/profile", data)
        .then((response) => {
          let payload = response.data.data;
          payload.profile_type = 2;
          commit("UPDATE_CURRENTUSER", payload);
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  submitCoperateProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/coporate/profile", data)
        .then((response) => {
          let payload = response.data.data;
          payload.profile_type = 1;
          commit("UPDATE_CURRENTUSER", payload);
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  viewIndividualProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .get("api/view/individual/profile", data)
        .then((response) => {
          if (response) {
            commit("SET_USER_PROFILE", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  viewCorporateProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .get("api/view/coporate/profile", data)
        .then((response) => {
          if (response) {
            commit("SET_USER_PROFILE", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  //    //
  editIndividualProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/update/individual/profile", data)
        .then((response) => {
          if (response) {
          }
          localStorage.setItem(
            "userName",
            response.data.data.firstname + " " + response.data.data.lastname
          );
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  editCoporateProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/update/coporate/profile", data)
        .then((response) => {
          if (response) {
            commit("SET_USER_PROFILE", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      commit("LOGOUT");
      resolve();
    });
  },
  toglleFirstTimeLogin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_IS_TIME_LOGIN", payload);
      resolve();
    });
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // todo
  // validate({ commit, state }) {
  //   if (!state.currentUser) return Promise.resolve(null);
  //   return axios
  //     .get("/api/session")
  //     .then((response) => {
  //       const user = response.data;
  //       //commit('SET_CURRENT_USER', user)
  //       return user;
  //     })
  //     .catch((error) => {
  //       if (error.response && error.response.status === 401) {
  //         //commit('SET_CURRENT_USER', null)
  //       }
  //       return null;
  //     });
  // },
  toggleReferal({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/toggle/referral", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  getReferral({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .get("api/get/referral", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  getUserProfileStatus() {
    return new Promise((resolve, reject) => {
      apiService
        .get("api/profile/status/check")
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  // General OTP SEND
  sendGeneralOTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/send/otp", data)
        .then((response) => {
          if (response) {
            resolve(response);
          }
        })
        .catch((err) => reject(err));
    });
  },

  setNumberNeedToVerify({ commit }, data) {
    localStorage.setItem("TempActivateNumber", data);
    return new Promise((resolve, reject) => {
      commit("SET_NUMBER_TO_VERIFY", data);
      resolve();
    });
  },
  getEmailVerificationStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/verify/email/account?user_id=${payload.user_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendEmailForVerification({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/send/email", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async parseOmniChannel({ commit }, payload) {
    try {
      return await new Promise((resolve) => {
        const omniChannelUrl = new URLSearchParams(window.location.search);
        if (
          omniChannelUrl.has("source") &&
          omniChannelUrl.has("product") &&
          omniChannelUrl.has("email")
        ) {
          const email_hash = omniChannelUrl.get("source");
          const product = omniChannelUrl.get("product");
          const email = omniChannelUrl.get("email");
          const data = {
            ...payload,
            email,
            product,
            email_hash,
            source: "omnichannel",
          };
          resolve(data);
          return data;
        }
        resolve(null);
        return null;
      });
    } catch (err) {}
  },

  subscribeToOmniChannel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/sso/connection", payload)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getTokenFromOmni({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .get(
          `api/get/sso/connection?email=${payload.email}&product=${payload.product}`
        )
        .then((response) => {
          commit("SET_CURRENT_USER", response.data);
          sessionStorage.setItem("userName", `${response.data.name}`);
          storage.saveState("source", "omniChannel");
          resolve(response.data);
          return response.data;
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export const getters = {
  isLoggedIn: () => !!state.currentUser,
  countryCode: (state) => state.countryCode,
  currentUser: (state) => state.currentUser,
  userProfile: (state) => state.userProfile,
  isFirstTimeLogin: (state) => state.isFirstTimeLogin,
  getresetWindowVisible: (state) => state.resetWindowVisible,
  getNumberToVerify: (state) => state.numberToVerify,
  disallowedUsers: (state) => {
    const admin = storage.getSavedState("auth.admin");
    // check if user is in the disallowed list

    return (
      state.disallowedUsers.some((id) => id == admin.user_id) ||
      admin.country === "CI"
    );
  },
};
