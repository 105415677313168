import mixpanel from "mixpanel-browser";

const mixpanelFranco = mixpanel.init(
  process.env.NODE_ENV === "production"
    ? "f210afed8c1f27937019df33263e222e"
    : "",
  {
    debug: process.env.NODE_ENV !== "production",
    track_pageview: true,
  },
  "franco"
);

export default {
  install(Vue) {
    Vue.prototype.$mixpanelFranco = mixpanelFranco;
  },
};
