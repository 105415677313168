import AdminService from "@/services/admin.service";
import Storage from "../../../services/storage";

const storage = new Storage();

export const state = {
  inAciveUsers: [],
  activeUsers: [],
  userSegments: [],
  getUserSegments: [],
  searchUserSegments: [],
  corporateUsers: [],
  smeUsers: [],
  UserSubscription: [],
  individualUsers: [],
  fraudulentUsers: [],
  unativatedUsers: [],
  deativatedUsers: [],
  users: [],
  loading: false,
  newUsers: [],
  newUsersToAndFrom: {
    from: "",
    to: "",
  },
  goldUsersTotal: 0,
  silverUsersTotal: 0,
  bronzeUsersTotal: 0,
};

export const mutations = {
  DEACTIVATE_USER(state, payload) {
    if (payload.from == "all") {
      state.users.splice(payload.index, 1);
    } else if (payload.from == "individual") {
      state.individualUsers.splice(payload.index, 1);
    } else if (payload.from == "corporate") {
      state.corporateUsers.splice(payload.index, 1);
    } else if (payload.from == "sme") {
      state.smeUsers.splice(payload.index, 1);
    } else if (payload.from == "tagged") {
    }
  },
  HANDLE_TAG_ACCOUNT(state, payload) {
    if (payload.from == "all") {
      state.users.data.splice(payload.index, 1);
    } else if (payload.from == "individual") {
      state.individualUsers.data.splice(payload.index, 1);
    } else if (payload.from == "corporate") {
      state.corporateUsers.data.splice(payload.index, 1);
    } else if (payload.from == "sme") {
      state.smeUsers.splice(payload.index, 1);
    } else if (payload.from == "tagged") {
      state.inAciveUsers.splice(payload.index, 1);
    } else if (payload.from == "inactive") {
      state.inAciveUsers.splice(payload.index, 1);
    }
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_ACTIVEUSERS(state, payload) {
    state.activeUsers.push(payload);
  },
  SET_DEACTIVATEDUSERS(state, payload) {
    state.deativatedUsers = payload;
  },
  SET_USERS(state, payload) {
    state.users = payload;
  },
  SET_INACTIVEUSERS(state, payload) {
    state.inAciveUsers = payload;
  },
  SET_UNACTIVATED_USERS(state, payload) {
    state.unativatedUsers = payload;
  },
  SET_USERSEGMENTS(state, payload) {
    state.userSegments = payload;
  },
  SET_CORPORATEUSERS(state, payload) {
    state.corporateUsers = payload;
  },
  SET_SMEUSERS(state, payload) {
    state.smeUsers = payload;
  },
  SET_INDIVIDUALUSERS(state, payload) {
    state.individualUsers = payload;
  },
  SET_FRAUDULENT_USERS(state, payload) {
    state.fraudulentUsers = payload;
  },
  REMOVE_ACTIVE_USER(state, payload) {
    if (payload.from == "tagged") {
      state.fraudulentUsers.splice(payload.index, 1);
    } else if (payload.from == "inactive") {
      state.inAciveUsers.splice(payload.index, 1);
    }
  },
  DELETE_USER(state, payload) {
    if (payload.from == "all") {
      state.users.splice(payload.index, 1);
    } else if (payload.from == "individual") {
      state.individualUsers.splice(payload.index, 1);
    } else if (payload.from == "corporate") {
      state.corporateUsers.splice(payload.index, 1);
    } else if (payload.from == "sme") {
      state.smeUsers.splice(payload.index, 1);
    } else if (payload.from == "tagged") {
      state.inAciveUsers.splice(payload.index, 1);
    }
  },
  SET_NEW_USERS(state, payload) {
    state.newUsers = payload;
  },
  SET_NEW_USERS_TO_AND_FROM(state, payload) {
    state.newUsersToAndFrom = payload;
  },
  SET_USERS_COUNT(state, payload) {
    state.users = payload.total;
  },
  SET_SEGMENT_COUNT(state, payload) {
    if (payload.category == 1) {
      state.goldUsersTotal = payload.data.data.total;
    }
    if (payload.category == 2) {
      state.silverUsersTotal = payload.data.data.total;
    }
    if (payload.category == 3) {
      state.bronzeUsersTotal = payload.data.data.total;
    }
  },
};

export const actions = {
  importFiles({ commit }, id) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/export/users/${id}?`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  exportNewUsers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/export/users/${payload.id}?from=${encodeURI(
          payload.from || ""
        )}&to=${encodeURI(payload.to || "")}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  impersonateUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/user/impersonate`, payload)
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getActiveUsers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // commit("SET_LOADING", true);
      AdminService.post(`api/users/get`, payload)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_ACTIVEUSERS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err))
        .finally(() => commit("SET_LOADING", false));
    });
  },
  allUsers({ commit }, payload = 1) {
    const country = storage.getSavedState("auth.admin").country;
    // commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      AdminService.get(`api/users/get?page=${payload}&country=${country}`)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_USERS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err))
        .finally(() => commit("SET_LOADING", false));
    });
  },
  getFraudulentUsers({ commit }) {
    // commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      AdminService.get(`api/get/fraudulent`)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_FRAUDULENT_USERS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err))
        .finally(() => commit("SET_LOADING", false));
    });
  },
  getInactiveUsers({ commit }, payload) {
    // commit("SET_LOADING", true);
    const country = storage.getSavedState("auth.admin").country;
    return new Promise((resolve, reject) => {
      AdminService.get(`api/inactive/users?country=${country}`, payload)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_INACTIVEUSERS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err))
        .finally(() => commit("SET_LOADING", false));
    });
  },
  getCorporateUsers({ commit }, payload) {
    const country = storage.getSavedState("auth.admin").country;
    // commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/coporate/users/get?page=${payload}&country=${country}`
      )
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_CORPORATEUSERS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err))
        .finally(() => commit("SET_LOADING", false));
    });
  },
  getSmeUsers({ commit }, payload) {
    // commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      AdminService.get(`api/get/all/smes`, payload)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_SMEUSERS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err))
        .finally(() => commit("SET_LOADING", false));
    });
  },
  getUserSegments({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/get/customer-segment/${payload.category}?page=${payload.page}`
      )
        .then(({ data: data }) => {
          commit("SET_SEGMENT_COUNT", {
            category: payload.category,
            data: data,
          });
          resolve(data.data);
        })
        .catch((err) => reject(err))
        .finally(() => commit("SET_LOADING", false));
    });
  },
  storeUserSegments({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/store/customer-segment`, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  searchUserSegments({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/search/customer-segment`, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => commit("SET_LOADING", false));
    });
  },
  getIndividualUsers({ commit }, payload = 1) {
    // commit("SET_LOADING", true);
    const country = storage.getSavedState("auth.admin").country;
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/individual/users/get?page=${payload}&country=${country}`
      )
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_INDIVIDUALUSERS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err))
        .finally(() => commit("SET_LOADING", false));
    });
  },
  // user actions
  activateUserAccounts({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/user/activate`, payload.data)
        .then(({ data: { message } }) => {
          commit("REMOVE_ACTIVE_USER", payload.actionData);
          resolve(message);
        })
        .catch((err) => reject(err));
    });
  },

  deleteUserAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/user/delete`, payload.data)
        .then(({ data: { data } }) => {
          if (data) {
            commit("DELETE_USER", payload);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },

  bulkDeleteUsers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/multiple/users/delete`, payload.data)
        .then(({ data: { data } }) => {
          if (data) {
            commit("DELETE_USER", payload);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },

  resetPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/admin/password/reset`, payload)
        .then(({ data: { message } }) => {
          resolve(message);
        })
        .catch((err) => reject(err));
    });
  },
  tagAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/user/tag/fraudulent`, payload)
        .then(({ data: { message } }) => {
          commit("HANDLE_TAG_ACCOUNT", payload);
          resolve(message);
        })
        .catch((err) => reject(err));
    });
  },
  bulkTagUsers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/user/bulk-tag/fraudulent`, payload)
        .then(({ data: { message } }) => {
          commit("HANDLE_TAG_ACCOUNT", payload);
          resolve(message);
        })
        .catch((err) => reject(err));
    });
  },
  deactiveUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/user/deactivate`, payload)
        .then(({ data: { message } }) => {
          commit("HANDLE_TAG_ACCOUNT", payload);
          resolve(message);
        })
        .catch((err) => reject(err));
    });
  },
  bulkDeactiveUsers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/user/bulk/deactivate`, payload)
        .then(({ data: { message } }) => {
          commit("HANDLE_TAG_ACCOUNT", payload);
          resolve(message);
        })
        .catch((err) => reject(err));
    });
  },
  topUpAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/user/wallet/deposit`, payload)
        .then(({ data: { message } }) => {
          resolve(message);
        })
        .catch((err) => reject(err));
    });
  },
  // Search all users using get
  searchAllUsers({ commit }, payload) {
    // commit("SET_LOADING", true);
    const country = storage.getSavedState("auth.admin").country;
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/search/user/all?search=${payload}&country=${country}`
      )
        .then((data) => {
          if (data) {
            commit("SET_USERS", data.data);
          }
          resolve(data);
        })
        .catch((err) => reject(err))
        .finally(() => commit("SET_LOADING", false));
    });
  },

  //   search individual users using get
  searchIndividualUsers({ commit }, payload) {
    // commit("SET_LOADING", true);
    const country = storage.getSavedState("auth.admin").country;
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/search/user/individual?search=${payload}&country=${country}`
      )
        .then((data) => {
          if (data) {
            commit("SET_INDIVIDUALUSERS", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err))
        .finally(() => commit("SET_LOADING", false));
    });
  },

  //   Search corporate users using get
  searchCorporateUsers({ commit }, payload) {
    // commit("SET_LOADING", true);
    const country = storage.getSavedState("auth.admin").country;
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/search/user/coporate?search=${payload}&country=${country}`
      )
        .then((data) => {
          if (data) {
            commit("SET_CORPORATEUSERS", data);
          }
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => commit("SET_LOADING", false));
    });
  },
  searchSmeUsers({ commit }, payload) {
    // commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      AdminService.get(`api/search/all/smes?search=${payload}`)
        .then((data) => {
          if (data) {
            commit("SET_SMEUSERS", data);
          }
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => commit("SET_LOADING", false));
    });
  },

  getNewUsers({ commit }, payload) {
    // commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      AdminService.get(
        `api/get/new/users?page=${payload.page}&from=${encodeURI(
          payload.from || ""
        )}&to=${encodeURI(payload.to || "")}`
      )
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_NEW_USERS", data.data);
          }
          resolve(data);
        })
        .catch((err) => reject(err))
        .finally(() => commit("SET_LOADING", false));
    });
  },
  toggleFollowUp({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/toggle/follow/up`, payload)
        .then(({ data: { message } }) => {
          resolve(message);
        })
        .catch((err) => reject(err));
    });
  },
  toggleUserCorporateIndividual({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/change/profile/type`, payload)
        .then(({ data: { message } }) => {
          resolve(message);
        })
        .catch((err) => reject(err));
    });
  },
  allowOpenSenderID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/senderid/allow/sender_id`, payload)
        .then(({ data: { message } }) => {
          resolve(message);
        })
        .catch((err) => reject(err));
    });
  },
  denyOpenSenderID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/senderid/deny/sender_id`, payload)
        .then(({ data: { message } }) => {
          resolve(message);
        })
        .catch((err) => reject(err));
    });
  },
  setNewUsersToAndFrom({ commit }, payload) {
    commit("SET_NEW_USERS_TO_AND_FROM", payload);
  },
};

export const getters = {
  loading: (state) => state.loading,
  inAciveUsers: (state) => state.inAciveUsers,
  userSegments: (state) => state.userSegments,
  corporateUsers: (state) => state.corporateUsers,
  smeUsers: (state) => state.smeUsers,
  individualUsers: (state) => state.individualUsers,
  fraudulentUsers: (state) => state.fraudulentUsers,
  unactivatedUsers: (state) => state.unactivatedUsers,
  deativatedUsers: (state) => state.deativatedUsers,
  users: (state) => state.users,
  newUsers: (state) => state.newUsers,
  getNewUsersFromAndTo: (state) => state.newUsersToAndFrom,
  goldUsersTotal: (state) => state.goldUsersTotal,
  silverUsersTotal: (state) => state.silverUsersTotal,
  bronzeUsersTotal: (state) => state.bronzeUsersTotal,
};
