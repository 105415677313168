var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('button', {
    staticClass: "backBtn",
    on: {
      "click": _vm.back
    }
  }, [_c('img', {
    attrs: {
      "height": "17",
      "src": require("@/assets/left-arrow (1).svg"),
      "alt": "img"
    }
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };