import AdminService from "@/services/admin.service";
export const state = {
  international_rates: [],
  internationalTemplates: []
};
export const mutations = {
  SET_INTERNATIONAL_RATE(state, payload) {
    state.international_rates = payload;
  },
  SET_INTERNATIONAL_TEMPLATES(state, payload) {
    state.internationalTemplates = payload;
  }
};
export const actions = {
  sendInternationalRate({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/store/international/rate`, payload).then(response => {
        if (response) {
          resolve(response);
        }
      }).catch(err => reject(err));
    });
  },
  getInternationalRate({
    commit
  }) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/get/international/rate`).then(response => {
        if (response) {
          commit("SET_INTERNATIONAL_RATE", response.data);
          resolve(response);
        }
      }).catch(err => reject(err));
    });
  },
  getInternationalTemplates({
    commit
  }) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/show/international/template`).then(response => {
        if (response) {
          commit("SET_INTERNATIONAL_TEMPLATES", response.data);
          resolve(response);
        }
      }).catch(err => reject(err));
    });
  },
  ApproveOrReject({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/update/template`, payload).then(response => {
        resolve(response);
      }).catch(err => reject(err));
    });
  }
};
export const getters = {
  international_rates: state => state.international_rates,
  internationalTemplates: state => state.internationalTemplates
};