import AdminService from "@/services/admin.service";
export const actions = {
  getWalletHistoryFr({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/get/users/wallet/history?page=${payload.page}`).then(({
        data: {
          data
        }
      }) => {
        resolve(data);
      }).catch(err => reject(err));
    });
  },
  searchWalletHistory({
    commit
  }, payload) {
    // commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      AdminService.get(`api/search/wallet/history?search=${payload.search}`).then(data => {
        resolve(data);
      }).catch(err => reject(err));
    });
  }
};