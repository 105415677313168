import apiService from "../../services/api.service";
import moment from "moment";
import loginType from "@/helpers/loginType.js";
// fetch login status

export const state = {
  billingSumarry: [],
  campaignSummary: [],
  campaignHistory: [],
  fetchingData: false,
  campaignDetails: [],
  totalReportingCreditUsed: 0,
};
export const mutations = {
  SET_CAMPAIGN_DETAILS(state, payload) {
    state.campaignDetails = payload;
  },
  SET_BILLING_SUMMARRY(state, payload) {
    state.billingSumarry = payload;
  },
  SET_CAMPAIGN_SUMMARRY(state, payload) {
    state.campaignSummary = payload;
  },
  SET_FETCHING_DATA(state, payload) {
    state.fetchingData = payload;
  },
  SET_CAMPAIGN_HISTORY(state, payload) {
    state.campaignHistory = payload;
  },
  SET_TOTAL_CREDIT_USED(state, payload) {
    state.totalReportingCreditUsed = payload;
  },
};
export const actions = {
  fetchCampaignSummary({ commit }) {
    commit("SET_FETCHING_DATA", true);
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/recent/campaign/summary`)
        .then((response) => {
          if (response) {
            commit("SET_CAMPAIGN_SUMMARRY", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err))
        .finally(() => commit("SET_FETCHING_DATA", false));
    });
  },
  fetchCampaignBillingSummary({ commit }) {
    let url =
      loginType() == "sms"
        ? `api/sms/billing/summary`
        : `api/recent/voice/campaign`;
    return new Promise((resolve, reject) => {
      apiService
        .get(url)
        .then((response) => {
          if (response) {
            commit("SET_BILLING_SUMMARRY", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  fetchSMSHistory({ commit }, payload = 1) {
    const url =
      loginType() == "sms"
        ? `api/sms/campaigns/history?page=${payload}`
        : `api/voice/campaigns/history?page=${payload}`;
    return new Promise((resolve, reject) => {
      apiService
        .get(url)
        .then(({ data: { data } }) => {
          commit("SET_CAMPAIGN_HISTORY", data);
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  fetchSMSHistoryDetails({ commit }, { id, page = 1, status = "" }) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/sms/campaign/details/${id}?page=${page}&status=${status}`)
        .then(({ data: { data } }) => {
          commit("SET_CAMPAIGN_DETAILS", data);
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  fetchReportingTotalCredit({ commit }, credit) {
    return new Promise((resolve, reject) => {
      commit("SET_TOTAL_CREDIT_USED", credit);
      resolve(credit);
    });
  },
  deliveryReport({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/sms/periodic/report", data)
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  smsDeliveryReportBySenderID({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/sms/sender-name/report", data)
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  minimumBalanceAlert({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/minimum/sms/balance", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },

  eportCampaignDetails({ commit }, campaign_id) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/campaign-details/export/${campaign_id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  exportCampaignHistoryVoice({ commit }, campaign_id) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/campaign-details/voice/export/${campaign_id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },

  //   This is for individual searching
  voiceReport({ commit }, campaign_id) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/voice/report`, {
          campaign_id: campaign_id,
          page: 1,
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  fetchVoiceHistory({ commit }, { id, page = 1 }) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/voice/campaign/details/${id}?page=${page}`)
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },

  //voice/campaign/details/{id}

  voiceStats({ commit }, campaign_id) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/get/stats`, {
          campaign_id,
          campaign_id,
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  //   This is for ALL voice delivery report
  deliveryReportVoice({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post("api/voice/periodic/report", data)
        .then(({ data: { data } }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  exportSMSDeliveryReport({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/delivery-report/sms/export?from=${data.from}&to=${data.to}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  exportVoiceDeliveryReport({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/delivery-report/voice/export?from=${data.from}&to=${data.to}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  /**
   * @param {{
   * service_type:string,
   * phone:string,
   * campaign_id:string,
   * }} data
   */
  searchCampaignHistory({ commit }, data) {
    return new Promise((resolve, reject) => {
      apiService
        .post(
          `api/search/campaign/report?service_type=${data.service_type}`,
          data
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
};
// GETTERS HELPERS
function validateBillingSummary(state) {
  if (!state.billingSumarry) return [];
}
function validateCampaignSummary(state) {
  if (!state.campaignSummary) return [];
}
export const getters = {
  billingSumarryCategory: function (state) {
    validateBillingSummary(state);
    let categories = [];
    state.billingSumarry.forEach((data) => {
      categories.push(moment(data.day).format("LL"));
    });
    return categories;
  },
  billingSumarryRecipients: function (state) {
    validateBillingSummary(state);
    let recipients = [];
    let creditUsed = [];
    state.billingSumarry.forEach((data) => {
      creditUsed.push(parseInt(data["Credit Used"]));
      recipients.push(
        parseInt(data["Message Recipients"] || data["Call Recipients"])
      );
    });
    return [
      {
        name: loginType() == "sms" ? "Total Recipients" : "Call Recipients",
        data: recipients,
      },
      { name: "Credit Used", data: creditUsed },
    ];
  },
  /*
    Delivered - Green 
    Not Delivered - Black 
    Submitted - Blue
    Failed - Red
*/
  campaignLabels: function (state) {
    validateCampaignSummary(state);
    const labels = [];
    const colors = [];
    state.campaignSummary.forEach((data) => {
      let dataLabel =
        data.status.charAt(0).toUpperCase() +
        data.status.slice(1).toLowerCase();

      // Change failed to accepted
      if (dataLabel === "Failed") {
        dataLabel = "Accepted";
      }
      labels.push(dataLabel);
      // get the status and the colors
      if (data.status.toLowerCase() === "DELIVERED".toLocaleLowerCase()) {
        colors.push("#36D962");
      } else if (
        data.status.toLowerCase() === "NOT_DELIVERED".toLocaleLowerCase()
      ) {
        colors.push("#000");
      } else if (
        data.status.toLowerCase() === "SUBMITTED".toLocaleLowerCase()
      ) {
        colors.push("#30A1F0");
      } else if (data.status.toLowerCase() === "FAILED".toLocaleLowerCase()) {
        colors.push("#f0c630");
      } else if (data.status.toLowerCase() === "EXPIRED".toLocaleLowerCase()) {
        colors.push("#F7921C");
      } else if (data.status.toLowerCase() === "REJECTED".toLocaleLowerCase()) {
        colors.push("#bc76ba");
      }
    });
    return [labels, colors];
  },
  campaignSeries: function (state) {
    validateCampaignSummary(state);
    let counts = [];
    state.campaignSummary.forEach((data) => {
      counts.push(data.counts);
    });
    return counts;
  },
  creditUsed: function (state) {
    validateCampaignSummary(state);
    let totalCreditUsed = 0;
    state.billingSumarry.forEach((summarry) => {
      totalCreditUsed += parseInt(Object.values(summarry)[2]);
    });
    return totalCreditUsed;
  },
  fetchingData: (state) => state.fetchingData,
  campaignHistory: (state) => state.campaignHistory,
  campaignDetails: (state) => state.campaignDetails,
  totalReportingCreditUsed: (state) => state.totalReportingCreditUsed,
};
