var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrapper"
  }, [_vm._m(0), _c('div', {
    staticClass: "__body"
  }, [_vm._m(1), _c('p', {
    staticClass: "__text"
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "header"
  }, [_c('h3', [_vm._v("Notice")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "__circle"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/sucess.svg"),
      "alt": "img"
    }
  })]);
}];
export { render, staticRenderFns };