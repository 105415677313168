import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "../src/state/store";
import ElementUI from "element-ui";

import "./assets/style/theme/index.css";
import VueTour from "vue-tour";
require("vue-tour/dist/vue-tour.css");
import "./styles/tour.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// Global Imports
import "jquery";
import Alert from "@/components/global/Alert.vue";
import profilesettingsBtn from "./components/global/profilesettingsbtn.vue";
import Export from "./components/global/exportComponent.vue";
import successMessage from "./components/global/succes.vue";
import ActionModal from "./components/global/ActionModal.vue";
import VCalendar from "v-calendar";
import moment from "moment";
import ComponentLoader from "./components/global/ComponentLoader.vue";
import VueApexCharts from "vue-apexcharts";

// USE VUE ELEMENT
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Hotjar from "vue-hotjar";

// Mix panel
import VueMixpanelBrowser from "vue-mixpanel-browser";
import mixpanel_franco from "./plugins/mixpanel_franco";

// Http Interceptor
import HttpInterceptor from "@/services/AxiosInterceptor";
import changeAppLanguage from "./components/language/changeAppLanguage.vue";
import vGoogleTranslate from "v-google-translate";
import i18n from "./i18n";

Vue.use(HttpInterceptor);
Vue.use(VueTour);
Vue.use(VueApexCharts);
Vue.use(VCalendar);
Vue.use(vGoogleTranslate);
Vue.component("apexchart", VueApexCharts);
//GLOBAL COMPONENTS
Vue.component("Alert", Alert);
Vue.component("ComponentLoader", ComponentLoader);
Vue.component("profilesettingsBtn", profilesettingsBtn);
Vue.component("Export", Export);
Vue.component("ActionModal", ActionModal);
Vue.component("successMessage", successMessage);
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) });
Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.component("changeAppLanguage", changeAppLanguage);

Vue.prototype.moment = moment;
Vue.config.productionTip = false;

// Mix panel
Vue.use(VueMixpanelBrowser, {
  token: process.env.VUE_APP_MIXPANEL_TOKEN,
  config: { debug: false },
});

// Hotjar
Vue.use(Hotjar, {
  id: process.env.VUE_APP_HOTJAR_ID,
  isProduction: process.env.NODE_ENV === "production",
});

/*
 * Mix panel Franco
 */
Vue.use(mixpanel_franco);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
