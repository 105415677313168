<template>
  <div ref="translateWrapper" :key="isActive">
    <v-google-translate
      class="translate-wrapper"
      :default-language-code="
        $store.getters['language/currentLanguage'] || 'en'
      "
      :fetchBrowserLanguage="false"
      :languages="languages"
      trigger="click"
      @select="handleGoogleTranslateSelect"
      v-if="isActive" />
  </div>
</template>

<script>
  import instanceManager from "./singletonInstanceManager";

  export default {
    data() {
      return {
        languages: [
          {
            code: "en",
            name: "English",
            cname: "英语",
            ename: "English",
          },
          {
            code: "fr",
            name: "Français",
            cname: "法语",
            ename: "French",
          },
        ],
        isActive: false,
      };
    },
    computed: {
      // url() {
      //   return process.env.VUE_APP_ENGINE_URL === "https://staging.mnotify.com";
      // },
      currentLanguage() {
        return this.$store.getters["language/currentLanguage"];
      },
    },
    methods: {
      /**
       * Handle Google Translate Select
       * @param {{
       * code:string,
       * name:string}} event
       */
      handleGoogleTranslateSelect(event) {
        this.$store.dispatch("language/setCurrentLanguage", event.code);
        this.$root.$i18n.locale = event.code;
      },
      removeDuplicateElements() {
        const elements = document.querySelectorAll(".skiptranslate");

        if (process.env.NODE_ENV === "development") {
          console.log("Google Translate elements found:", elements.length);
        }

        if (elements.length > 1) {
          // Keep the first element and remove the rest
          for (let i = 1; i < elements.length; i++) {
            elements[i].remove();
          }

          // Also remove any duplicate script tags
          const scripts = document.querySelectorAll(
            'script[src^="https://translate.google.com"]'
          );
          if (scripts.length > 1) {
            for (let i = 1; i < scripts.length; i++) {
              scripts[i].remove();
            }
          }

          if (process.env.NODE_ENV === "development") {
            console.log("Removed duplicate Google Translate elements");
          }
        }

        // Clean up any leftover iframes
        const iframes = document.querySelectorAll(
          'iframe[src^="https://translate.google.com"]'
        );
        iframes.forEach((iframe) => {
          if (!document.querySelector(".skiptranslate").contains(iframe)) {
            iframe.remove();
          }
        });
      },
      initTawk() {
        var chatId = this.getChatId();
        if (!chatId) {
          return;
        }

        if (window.Tawk_API) {
          // If Tawk_API is already initialized, use switchWidget
          this.switchTawkWidget(chatId);
        } else {
          // If Tawk_API is not initialized, load the script as before
          var Tawk_API = Tawk_API || {},
            Tawk_LoadStart = new Date();

          var s1 = document.createElement("script"),
            s0 = document.getElementsByTagName("script")[0];
          s1.async = true;
          s1.src = "https://embed.tawk.to/" + chatId;
          s1.charset = "UTF-8";
          s0.parentNode.insertBefore(s1, s0);
        }
      },
      switchTawkWidget(chatId) {
        const [propertyId, widgetId] = chatId.split("/");

        window.Tawk_API.switchWidget(
          {
            propertyId: propertyId,
            widgetId: widgetId,
          },
          function (error) {
            if (error) {
              console.error("Error switching Tawk widget:", error);
            } else {
              console.log("Successfully switched Tawk widget");
            }
          }
        );
      },
      getChatId() {
        // function to retrieve the property/widget chat id;
        // substitute 000000000001 to property id

        var lang = this.currentLanguage || "en";

        // return the default language if the lang variable is empty
        if (!lang) {
          return "61f16f02b9e4e21181bc0788/1fqbhj98r";
        }

        if (lang.match("en")) {
          return "61f16f02b9e4e21181bc0788/1fqbhj98r";
        } else if (lang.match("fr")) {
          return "61f16f02b9e4e21181bc0788/1i4rqghq9";
        } else {
          return "61f16f02b9e4e21181bc0788/1fqbhj98r";
        }
      },
    },
    mounted() {
      this.removeDuplicateElements();
      this.isActive = false;
      if (instanceManager.activate()) {
        this.isActive = true;
        if (process.env.NODE_ENV === "development") {
          console.log("Google Translate component activated.");
        }
      } else {
        if (process.env.NODE_ENV === "development") {
          console.warn("Google Translate instance is already active.");
        }
        this.$refs.translateWrapper.remove(); // or any cleanup needed
      }
    },
    watch: {
      isActive(value) {
        if (value) {
          this.removeDuplicateElements();
        }
      },
      currentLanguage(value) {
        // re initialize the tawk script
        this.initTawk();
      },
    },
    beforeDestroy() {
      instanceManager.deactivate();
    },
    beforeRouteLeave() {
      this.removeDuplicateElements();
    },
    created() {
      this.initTawk();
    },
  };
</script>

<style scoped>
  .translate-wrapper {
    max-width: fit-content;
    margin: 0 auto;
    padding-bottom: 1rem;
  }
</style>

<style>
  /* Hides the Google Translate toolbar and icon */
  .goog-te-banner-frame.skiptranslate,
  .goog-te-gadget-icon {
    display: none !important;
  }

  /* Hide other elements if necessary */
  .goog-te-banner-frame,
  .goog-te-banner {
    display: none !important;
  }

  .translate-wrapper {
    max-width: fit-content;
    margin: 0 auto;
    padding-bottom: 1rem;
  }

  /* If you need to hide the logo in the bottom right */
  .goog-logo-link {
    display: none !important;
  }

  .VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
    display: none !important;
  }
</style>
