import "core-js/modules/es.array.push.js";
import AdminService from "@/services/admin.service";
export const state = {
  industries: []
};
export const mutations = {
  SET_INDUSTRY(state, payload) {
    state.industries = payload;
  },
  ADD_INDUSTRY(state, payload) {
    state.industries.push(payload);
  },
  EDIT_INDUSTRY(state, payload) {
    state.industries[payload.key].industry_name = payload.industry_name;
    state.industries = [...state.industries];
  },
  DELETE_INDUSTRY(state, payload) {
    state.industries.splice(payload.key, 1);
    state.industries = [...state.industries];
  }
};
export const actions = {
  getIndustryReporting({
    commit
  }) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/industries/get`).then(({
        data: {
          data
        }
      }) => {
        if (data) {
          commit('SET_INDUSTRY', data);
        }
        resolve(data);
      }).catch(err => reject(err));
    });
  },
  createIndustry({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/industry/create`, payload).then(({
        data: {
          data,
          message
        }
      }) => {
        if (data) {
          commit('ADD_INDUSTRY', data);
        }
        resolve(message);
      }).catch(err => reject(err));
    });
  },
  editIndustry({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/industry/edit`, payload).then(({
        data: {
          message
        }
      }) => {
        commit('EDIT_INDUSTRY', payload);
        resolve(message);
      }).catch(err => reject(err));
    });
  },
  deleteIndustry({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      AdminService.post(`api/industry/delete`, payload).then(({
        data: {
          message
        }
      }) => {
        commit('DELETE_INDUSTRY', payload);
        resolve(message);
      }).catch(err => reject(err));
    });
  }
};
export const getters = {
  industries: state => state.industries
};