import apiService from "../../services/api.service";
import adminService from "../../services/admin.service";
import axios from "axios";
export const state = {
  senderIDs: [],
  senderModalState: {},
  loadingSenderIDS: false,
  approvedSenderIDs: [],
  pendingSenderIDs: [],
  declinedSenderIDS: [],
  holdingSenderIDs: [],
  allSenderIDs: [],
  loading: false,
  autoApprovedSenderIDs: [],
  reservedSenderIds: [],
  resellerPendingSenderIds: [],
  resellerApprovedSenderIds: [],
  resellerDeclinedSenderIds: [],
  resellerOnHoldSenderIds: [],
};
export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  TOGGLE_SENDER_ID(state, payload) {
    // if (payload.senderType == "pending") {
    //   state.pendingSenderIDs.splice(payload.index, 1);
    //   state.pendingSenderIDs = [...state.pendingSenderIDs];
    //   return;
    // }
    // if (payload.senderType == "approved") {
    //   state.approvedSenderIDs.splice(payload.index, 1);
    //   state.approvedSenderIDs = [...state.approvedSenderIDs];
    //   return;
    // }
    // if (payload.senderType == "declined") {
    //   state.declinedSenderIDS.splice(payload.index, 1);
    //   state.declinedSenderIDS = [...state.declinedSenderIDS];
    //   return;
    // }
    // if (payload.senderType == "hold-on") {
    //   state.holdingSenderIDs.splice(payload.index, 1);
    //   state.holdingSenderIDs = [...state.holdingSenderIDs];
    //   return;
    // }
    // if (payload.senderType == "autoApproved") {
    //   state.autoAprovedSenderIDs.splice(payload.index, 1);
    //   state.autoApprovedSenderIDs = [...state.autoApprovedSenderIDs];
    //   return;
    // }
  },
  SET_SENDER_IDs_ADMIN(state, senderIDS) {
    state.pendingSenderIDs = senderIDS.data.filter(
      (sender) => sender.status == "0"
    );
    state.resellerApprovedSenderIds = senderIDS.data.filter(
      (sender) => sender.status == "1"
    );
    state.resellerDeclinedSenderIds = senderIDS.data.filter(
      (sender) => sender.status == "2"
    );
    state.holdingSenderIDs = senderIDS.data.filter(
      (sender) => sender.status == "3"
    );
    state.autoApprovedSenderIDs = senderIDS.data.filter(
      (sender) => sender.approved_by == "SYSTEM"
    );
  },

  SET_RESELLER_SENDER_IDS_ADMIN(state, senderIDS) {
    state.reseller = senderIDS.data.filter((sender) => sender.status == "0");
    state.approvedSenderIDs = senderIDS.data.filter(
      (sender) => sender.status == "1"
    );
    state.declinedSenderIDS = senderIDS.data.filter(
      (sender) => sender.status == "2"
    );
    state.holdingSenderIDs = senderIDS.data.filter(
      (sender) => sender.status == "3"
    );
    state.resellerOnHoldSenderIds = senderIDS.data.filter(
      (sender) => sender.status == "3"
    );
  },
  SET_SENDERID(state, payload) {
    state.allSenderIDs = payload;
    const filteredSenderIDs = payload.filter((sender) => sender.status === "1");
    state.senderIDs = filteredSenderIDs;
  },
  ADD_SENDER_ID(state, payload) {
    state.allSenderIDs.unshift(payload);
    if (payload.status == 1) {
      state.senderIDs.unshift(payload);
    }
  },
  DELETE_SENDER_ID(state, key) {
    state.senderIDs.splice(key, 1);
    state.allSenderIDs.splice(key, 1);
  },
  SET_SENDER_ID_STATE(state, payload) {
    state.senderModalState = payload;
  },
  SET_LOADING_SENDER_IDS(state, payload) {
    state.loadingSenderIDS = payload;
  },
  SET_COUNT(state, payload) {
    state.pendingSenderIDs = payload.count_pending;
    state.approvedSenderIDs = payload.count_approved;
    state.declinedSenderIDS = payload.count_declined;
    state.holdingSenderIDs = payload.count_onhold;
    state.autoApprovedSenderIDs = payload.count_auto_approved;
    state.reservedSenderIds = payload.count_reserved;
  },
  // SET_RESELLER_COUNT(state,payload){
  //   state.pendingResellerSenderIDs = payload.count_pendingReseller;
  //   state.approvedResellerSenderIDs = payload.count_approvedReseller;
  //   state.declinedResellerSenderIDS = payload.count_declinedReseller;
  // }
};
export const actions = {
  //for admin so use admin service
  adminSenderIDs({ commit }, payload) {
    return new Promise((resolve, reject) => {
      adminService
        .get(`api/sender-ids/get?page=${payload.page}&status=${payload.status}`)
        .then(({ data: { data } }) => {
          if (data) {
            commit("SET_COUNT", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err))
        .finally(() => commit("SET_LOADING", false));
    });
  },
  searchSenderIdAdmin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      adminService
        .get(
          `api/search/sender/id?search=${payload.search}&status=${payload.status}`
        )
        .then((data) => {
          resolve(data);
        })
        .catch((err) => reject(err))
        .finally(() => commit("SET_LOADING", false));
    });
  },
  registerSenderID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`api/senderid/register`, payload)
        .then((response) => {
          if (response) {
            commit("ADD_SENDER_ID", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  resellerSenderIDs({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `https://reseller.mnotifybms.com/api/all/senderids?page=${payload.page}&status=${payload.status}`
        )
        .then(({ data }) => {
          if (data) {
            // commit("SET_RESELLER_SENDER_IDS_ADMIN", data);
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },

  searchResellerSenderId({ commit }, payload) {
    return new Promise((resolve, reject) => {
      adminService;
      axios
        .get(
          `https://reseller.mnotifybms.com/api/search/senderids?status=${payload.status}&search=${payload.search}`
        )
        .then((data) => {
          resolve(data);
        })
        .catch((err) => reject(err));
      // .finally(() => commit("SET_LOADING", false));
    });
  },
  getSenderIDs({ commit }) {
    commit("SET_LOADING_SENDER_IDS", true);
    return new Promise((resolve, reject) => {
      apiService
        .get(`api/senderids/list`)
        .then((response) => {
          if (response) {
            commit("SET_SENDERID", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err))
        .finally(() => commit("SET_LOADING_SENDER_IDS", false));
    });
  },

  deleteSenderID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .delete(`api/senderid/delete/${payload.id}`)
        .then((response) => {
          if (response) {
            commit("DELETE_SENDER_ID", payload.key);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  bulkDeleteSenderIDs({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .delete(`api/bulk/senderid/delete/${payload.id}`)
        .then((response) => {
          if (response) {
            commit("DELETE_SENDER_ID", payload.key);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  //for admin so use admin service
  toggleSenderID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      adminService
        .post("api/senderid/toggle/status", payload.data)
        .then((data) => {
          if (data) {
            // commit("TOGGLE_SENDER_ID", payload.stateData);
          }
          resolve(data.data);
        })
        .catch((err) => reject(err));
    });
  },
  bulkToggleSenderID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      adminService
        .post("api/senderid/bulk-toggle/status", payload.data)
        .then((data) => {
          if (data) {
            // commit("TOGGLE_SENDER_ID", payload.stateData);
          }
          resolve(data.data);
        })
        .catch((err) => reject(err));
    });
  },

  //for admin so use admin service for resellers
  toggleResellerSenderID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      adminService
        .post("api/senderid/reseller/toggle/status", payload.data)
        .then((data) => {
          if (data) {
            // commit("TOGGLE_SENDER_ID", payload.stateData);
          }
          //add success message here

          resolve(data.data);
        })
        .catch((err) => reject(err));
    });
  },
  bulkToggleResellerSenderID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      adminService
        .post("api/senderid/reseller/bulk-toggle/status", payload.data)
        .then((data) => {
          if (data) {
            // commit("TOGGLE_SENDER_ID", payload.stateData);
          }
          //add success message here

          resolve(data.data);
        })
        .catch((err) => reject(err));
    });
  },

  setSenderModalState({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_SENDER_ID_STATE", payload);
      resolve(payload);
    });
  },

  // Reserved Sender Ids

  addReservedSenderIDs({ commit }, payload) {
    return new Promise((resolve, reject) => {
      adminService
        .post(`api/reserved/senderid`, payload)
        .then((response) => {
          if (response) {
            // commit("ADD_RESERVED_SENDER_IDS", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  viewReservedSenderIDs({ commit }) {
    commit("SET_LOADING_SENDER_IDS", true);
    return new Promise((resolve, reject) => {
      adminService
        .get(`api/get/reserved/senderid`)
        .then((response) => {
          if (response) {
            // commit("SET_SENDERID", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
      // .finally(() => commit("VIEW_RESERVED_SENDER_IDS", false));
    });
  },

  deleteReservedSenderID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`delete/reserved/senderid`, payload)
        .then((response) => {
          if (response) {
            commit("DELETE_RESREVED_SENDER_IDS", response.data.data);
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  bulkActionSenderID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      adminService
        .post(`api/senderid/bulk-toggle/status `, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  bulkActionResellerSenderID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      adminService
        .post(`api/senderid/reseller/bulk-toggle/status `, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
};

export const getters = {
  allSenderIDs: (state) => state.allSenderIDs,
  senderIDs: (state) => state.senderIDs,
  senderModalState: (state) => state.senderModalState,
  approvedSenderIDs: (state) => state.approvedSenderIDs,
  pendingSenderIDs: (state) => state.pendingSenderIDs,
  declinedSenderIDS: (state) => state.declinedSenderIDS,
  resellerApprovedSenderIDs: (state) => state.resellerApprovedSenderIDs,
  resellerPendingSenderIDs: (state) => state.resellerPendingSenderIDs,
  resellerDeclinedSenderIDS: (state) => state.resellerDeclinedSenderIDS,
  resellerOnHoldSenderIds: (state) => state.resellerOnHoldSenderIds,
  holdingSenderIDs: (state) => state.holdingSenderIDs,
  loadingSenderIDS: (state) => state.loadingSenderIDS,
  loading: (state) => state.loading,
  autoApprovedSenderIDs: (state) => state.autoApprovedSenderIDs,
  reservedSenderIds: (state) => state.reservedSenderIds,
  resellerPendingSenderIDs: (state) => state.resellerPendingSenderIds,
};
