import apiService from "../../services/api.service";
export const state = {
  international_rates_client: []
};
export const mutations = {};
export const actions = {
  getRates({
    commit
  }) {
    return new Promise((resolve, reject) => {
      apiService.get(`api/get/international/rate`).then(response => {
        if (response) {
          resolve(response);
        }
      }).catch(err => reject(err));
    });
  }
};
export const getters = {
  international_rates_client: state => state.international_rates_client
};