import AdminService from "@/services/admin.service";
export const state = {
  categories: []
};
export const mutations = {
  SET_CATEGORIES(state, payload) {
    state.categories = payload.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  }
};
export const actions = {
  getCategories({
    commit
  }) {
    return new Promise((resolve, reject) => {
      AdminService.get(`api/categorize/users`).then(({
        data: {
          data
        }
      }) => {
        if (data) {
          commit('SET_CATEGORIES', data);
        }
        resolve(data);
      }).catch(err => reject(err));
    });
  }
};
export const getters = {
  categories: state => state.categories
};