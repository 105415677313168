var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row mt-5 pt-5"
  }, [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "col-md-12"
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };