<template>
  <div
    class="modal fade"
    id="actionModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="contact"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="dialog">
      <div class="modal-content d-flex align-items-center" v-if="success">
        <successMessage :message="success" />
        <button @click="hideModal" class="__close__success__modal">
          Close
        </button>
      </div>
      <div class="modal-content pb-3" v-if="!success">
        <div class="modal-body m-0 p-0">
          <div class="row justify-content-center mb-5 mt-5">
            <div
              class="col-md-9 text-center my-4"
              v-if="payload.iconType == 'delete'">
              <img src="../../assets/Delete.svg" alt="img" />
            </div>
            <div class="col-md-9">
              <p class="text-center">
                {{ payload.message }}
              </p>
            </div>
          </div>
          <div class="row justify-content-center mb-4">
            <div
              class="col-md-9 modal__footer__btns d-flex justify-content-between mx-3">
              <el-button
                style=""
                type="danger"
                :loading="loading"
                id="cancelinput"
                @click="submitAction"
                >{{ payload.actionMessage }}</el-button
              >
              <button style="width: 123px" class="cancel" @click="hideModal">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  import $ from "jquery";
  export default {
    props: {
      payload: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        success: "",
        error: "",
        loading: false,
      };
    },
    methods: {
      hideModal() {
        this.error = "";
        this.success = "";
        this.loading = false;
        this.$emit("close");
        $("#actionModal").modal("hide");
      },
      activeUser() {
        let payloadData = {
          data: {
            user_id: this.payload.data.id,
          },
          actionData: {
            from: this.payload.from,
            index: this.payload.index,
          },
        };
        return store
          .dispatch("admin/users/activateUserAccounts", payloadData)
          .then((message) => {
            this.loading = false;
            this.success = message;
          })
          .catch((err) => {
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          });
      },
      deactiveUser() {
        let payloadData = {
          user_id: this.payload.data.id,
          from: this.payload.from,
          index: this.payload.index,
        };
        return store
          .dispatch("admin/users/deactiveUser", payloadData)
          .then((message) => {
            this.loading = false;
            this.success = message;
          })
          .catch((err) => {
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          });
      },
      tagAccount() {
        let payloadData = {
          user_id: this.payload.data.id,
          from: this.payload.from,
          index: this.payload.index,
        };
        return store
          .dispatch("admin/users/tagAccount", payloadData)
          .then((message) => {
            this.loading = false;
            this.success = message;
          })
          .catch((err) => {
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          });
      },
      resestPaswword() {
        let payloadData = {
          phone: this.payload.data.phone,
        };
        return store
          .dispatch("admin/users/resetPassword", payloadData)
          .then((message) => {
            this.loading = false;
            this.success = message;
            this.$emit("test");
          })
          .catch((err) => {
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          });
      },
      toggleSenderID() {
        let payloadData = {
          data: {
            status: this.payload.status,
            id: this.payload.data.id,
          },
          stateData: {
            index: this.payload.index,
            senderType: this.payload.senderType,
          },
        };
        return store
          .dispatch("senderid/toggleSenderID", payloadData)
          .then((message) => {
            this.loading = false;
            this.success = message.message;
            this.$emit("needsToRefresh");
          })
          .catch((err) => {
            this.loading = false;
            this.$emit("needsToRefresh");
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          });
      },
      toggleResellerSenderID() {
        let payloadData = {
          data: {
            status: this.payload.status,
            id: this.payload.data.id,
          },
          stateData: {
            index: this.payload.index,
            senderType: this.payload.senderType,
          },
        };
        return store
          .dispatch("senderid/toggleResellerSenderID", payloadData)
          .then((message) => {
            this.loading = false;
            this.success = message.message;
            this.$emit("needsToRefresh");
          })
          .catch((err) => {
            this.loading = false;
            this.$emit("needsToRefresh");
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          });
      },
      deleteNofication() {
        let payloadData = {
          notification_id: this.payload.data.id,
          index: this.payload.index,
        };
        this.loading = true;
        return store
          .dispatch("admin/notifications/deleteNotification", payloadData)
          .then((message) => {
            this.loading = false;
            this.success = message;
          })
          .catch((err) => {
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          })
          .finally(() => (this.loading = false));
      },
      confirmAccount() {
        let payloadData = {
          id: this.payload.data.id,
          index: this.payload.$index,
        };
        this.loading = true;
        return store
          .dispatch("admin/reseller/confirmReseller", payloadData)
          .then((message) => {
            this.loading = false;
            this.success = message;
          })
          .catch((err) => {
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          })
          .finally(() => (this.loading = false));
      },
      togglePromoCode() {
        let payloadData = {
          id: this.payload.data.row.id,
          index: this.payload.data.$index,
          status: this.payload.status,
        };
        return store
          .dispatch("admin/promocodes/togglePromoCode", payloadData)
          .then((message) => {
            this.loading = false;
            this.success = message;
          })
          .catch((err) => {
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          })
          .finally(() => (this.loading = false));
      },
      deleteIndustry() {
        let payloadData = {
          industry_id: this.payload.data.row.id,
          key: this.payload.data.$index,
        };
        return store
          .dispatch("admin/industries/deleteIndustry", payloadData)
          .then((message) => {
            this.loading = false;
            this.success = message;
          })
          .catch((err) => {
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          })
          .finally(() => (this.loading = false));
      },
      unpublishAction() {
        let payloadData = {
          notification_id: this.payload.data.id,
          index: this.payload.index,
        };
        return store
          .dispatch("admin/notifications/unpublishNotification", payloadData)
          .then((message) => {
            this.loading = false;
            this.success = message.data.message;
          })
          .catch((err) => {
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          })
          .finally(() => (this.loading = false));
      },
      approveAction() {
        let payloadData = {
          data: {
            status: this.payload.status,
            id: this.payload.data.id,
          },
          stateData: {
            index: this.payload.index,
            senderType: this.payload.senderType,
          },
        };
        return store
          .dispatch("senderid/getSenderIDs", payloadData)
          .then(() => {
            this.$message({
              type: "success",
              message: " Approved",
            });
            this.loading = false;
            this.success = message;
          })
          .catch(() => {
            this.this.$message({
              type: "error",
              message: "There was an error",
            });
          });
      },
      toggleFraudWhitelist() {
        let payloadData = {
          user_id: this.payload.data.id,
          fraud_ignore_status:
            this.payload.data.fraud_ignore_status == 1 ? 0 : 1 || 1,
        };
        return store
          .dispatch("admin/fraudSystem/toggleFraudWhitelist", payloadData)
          .then((data) => {
            this.loading = false;
            this.success = data.message;
            this.$emit("needsToRefresh");
          })
          .catch((err) => {
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          });
      },
      submitAction() {
        this.loading = true;
        if (this.payload.action == "resetPassword") {
          return this.resestPaswword();
        }
        if (this.payload.action == "tagAccount") {
          return this.tagAccount();
        }
        if (this.payload.action == "deactiveUser") {
          return this.deactiveUser();
        }
        if (this.payload.action == "senderID") {
          return this.toggleSenderID();
        }
        if (this.payload.action == "resSenderID") {
          return this.toggleResellerSenderID();
        }
        if (this.payload.action == "deleteNotification") {
          return this.deleteNofication();
        }
        if (this.payload.action == "confirmRequest") {
          return this.confirmAccount();
        }
        if (this.payload.action == "togglePromoCode") {
          return this.togglePromoCode();
        }
        if (this.payload.action == "activate") {
          return this.activeUser();
        }
        if (this.payload.action == "deleteIndustry") {
          return this.deleteIndustry();
        }
        if (this.payload.action == "unpublishNotification") {
          return this.unpublishAction();
        }
        if (this.payload.action === "toggleFraudWhitelist") {
          return this.toggleFraudWhitelist();
        }
      },
    },
    mounted() {
      this.error = "";
      this.success = "";
      this.loading = false;
    },
  };
</script>

<style scoped></style>
